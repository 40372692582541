@if (newEntryItem) {
  <div [attr.data-automationId]="'new-entry-card-' + newEntryItem.name" class="info-card">
    <i class="pi {{ newEntryItem.icon }} icon"></i>
    <div class="title">{{ newEntryItem.label }}</div>
    <div class="description">{{ newEntryItem.description }}</div>

    <p-button
      (click)="addElement(newEntryItem.entry)"
      class="add-btn"
      data-automationId="new-entry-add-btn"
      icon="pi {{ ICON.ADD }}"
      [rounded]="true"
      [text]="true"
    ></p-button>
  </div>
}
