<div #overlayPanelTarget *ngIf="config" class="p-fluid">
  <p-tabView styleClass="no-background">
    <p-tabPanel header="Optionen">
      <ng-content></ng-content>
      <kfd-required-cfg-setting (valueChange)="change('required', $event)" [value]="config.required" class="mt-2">
      </kfd-required-cfg-setting>
      <div class="horizontal-line"></div>
      <kfd-toggle-setting
        (disable)="change('min', undefined); change('max', undefined)"
        (enable)="enableMultiSelect()"
        [object]="config"
        [property]="'max'"
        class="p-field"
        data-automationId="numeric-config-multi"
        label="Mehrfachauswahl"
      >
        <div class="setting-label">Minimale Anzahl</div>
        <kfd-data-value
          (valueChange)="change('min', $event)"
          [properties]="{ showButtons: true, min: 0, max: config.max ? config.max.value - 1 : 99 }"
          [type]="dataValueType.NUMERIC"
          [value]="config.min"
          data-automationId="numeric-config-min"
        ></kfd-data-value>
        <div class="setting-label">Maximale Anzahl</div>
        <kfd-data-value
          (valueChange)="change('max', $event)"
          [properties]="{ showButtons: true, min: config.min ? config.min.value + 1 : 1 }"
          [type]="dataValueType.NUMERIC"
          [value]="config.max"
          data-automationId="numeric-config-max"
        ></kfd-data-value>
      </kfd-toggle-setting>

      <div class="horizontal-line"></div>

      <div class="setting-label mt-2">Auswahl Optionen</div>
      <div class="flex" data-automationId="select-option-settings">
        <div class="flex-1">
          <ng-container *ngIf="!config.dataHandler">
            <i>Keine Optionen ausgewählt</i>
          </ng-container>
          <ng-container *ngIf="config.dataHandler?.type === dataHandlerTypes.LIST">
            {{ config.dataHandler.data ? config.dataHandler.data.length : 0 }}
            Optionen
          </ng-container>
          <ng-container *ngIf="config.dataHandler?.type === dataHandlerTypes.DYNAMIC">
            @if (config.dataHandler.templateName) {
              <div>Vorlage: {{ config.dataHandler.templateName }}</div>
            }
            @if (config.dataHandler.tags && config.dataHandler.tags.length > 0) {
              <div>Tags: {{ config.dataHandler.tags.join(', ') }}</div>
            }
          </ng-container>
        </div>
        <div>
          <p-button
            (click)="showDataValuePanel()"
            [text]="true"
            data-automationId="edit-select-options"
            icon="pi {{ icon.EDIT }}"
            pTooltip="Auswahloptionen bearbeiten"
          ></p-button>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Aussehen">
      <div class="p-field">
        <div class="setting-label">Art der Anzeige</div>
        <p-dropdown
          (onChange)="change('layout.type', $event.value)"
          [ngModel]="config.layout?.type"
          [options]="layoutOptions"
          appendTo="body"
          data-automationId="config-layout-type"
        >
        </p-dropdown>
      </div>

      <div class="horizontal-line"></div>

      <div class="p-field">
        <div class="setting-label">Deselektierung</div>
        <kfd-toggle-setting
          (disable)="change('emptySelection', undefined)"
          (enable)="change('emptySelection', '---')"
          [object]="config"
          [property]="'emptySelection'"
          label="Leer-Wert anzeigen"
        >
          <input
            (ngModelChange)="change('emptySelection', $event)"
            [ngModel]="config.emptySelection"
            maxlength="30"
            pInputText
            type="text"
          />
        </kfd-toggle-setting>
      </div>

      <div class="horizontal-line"></div>
      <!--      @if (config.layout?.type === SELECT_FIELD_LAYOUTS.BUTTON) {-->
      <!--      }-->
      @if (additionalFieldOptions$ | async; as additionalFieldOptions) {
        <div class="p-field">
          <div class="setting-label">Zusätzliche Informationen</div>
          <kfd-label-value-input
            [valueOptions]="additionalFieldOptions"
            (ngModelChange)="change('additionalValues', $event)"
            [ngModel]="config.additionalValues"
          ></kfd-label-value-input>
        </div>
      }
    </p-tabPanel>
  </p-tabView>
</div>
