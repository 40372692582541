@if (viewData$ | async; as viewData) {
  <div
    [kfdBlock]="viewData.persistenceState >= persistenceState.RETRY"
    [text]="'Bitte warten'"
    class="flex flex-column flex-1 overflow-hidden"
  >
    <kfd-header-toolbar [headline]="viewData.configuration.label" data-automationId="cfg-editor">
      <ng-container topRight>
        <ng-container *ngIf="viewData.editMode === true">
          <p-button
            (click)="cfgChecksOverlay.toggle($event)"
            *ngIf="viewData.checkResults.length > 0"
            [badge]="'' + viewData.checkResults.length"
            [disabled]="!viewData.actionsEnabled"
            [outlined]="true"
            [rounded]="true"
            badgeClass="p-badge-warning"
            class="rounded mx-1"
            data-automationId="cfg-check-btn-with-warnings"
            icon="pi {{ ICON.WARN }}"
            pTooltip="Überprüfen"
            severity="warning"
          ></p-button>
          <p-button
            (click)="cfgChecksOverlay.toggle($event)"
            *ngIf="viewData.checkResults.length === 0"
            [disabled]="!viewData.actionsEnabled"
            [outlined]="true"
            [rounded]="true"
            class="mx-1"
            data-automationId="cfg-check-btn-no-warnings"
            icon="pi {{ ICON.ACCEPT }}"
            pTooltip="Überprüfen"
          ></p-button>
          <div class="vertical-line"></div>
        </ng-container>
        <p-button
          (click)="openPreview()"
          [disabled]="!viewData.actionsEnabled"
          [outlined]="true"
          [rounded]="true"
          class="mx-1"
          data-automationId="cfg-preview-btn"
          icon="pi {{ ICON.PREVIEW }}"
          pTooltip="Vorschau"
        ></p-button>
        <p-button
          (click)="openPublishing()"
          [disabled]="!viewData.actionsEnabled"
          [outlined]="true"
          [rounded]="true"
          class="mx-1"
          data-automationId="cfg-publish-btn"
          icon="pi {{ ICON.PUBLISH }}"
          pTooltip="Veröffentlichen"
        ></p-button>
        <div class="vertical-line"></div>
        <p-button
          (click)="enableEditMode(); editHintOverlay.hide()"
          (visible)="editHintOverlay.show($event)"
          *ngIf="viewData.editMode === false"
          [outlined]="true"
          [rounded]="true"
          class="mx-1"
          data-automationId="cfg-btn-editmode"
          icon="pi {{ ICON.EDIT }}"
          pTooltip="Bearbeiten"
          visible
        ></p-button>

        <ng-container *ngIf="viewData.editMode === false">
          <p-button
            (click)="menu.toggle($event)"
            [disabled]="!viewData.actionsEnabled"
            [outlined]="true"
            [rounded]="true"
            class="mx-1"
            data-automationId="cfg-viewmode-menu"
            icon="pi {{ ICON.MENU }}"
          ></p-button>
          <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body"></p-menu>
        </ng-container>
        <ng-container *ngIf="viewData.editMode">
          <p-button
            (click)="this.cfgEditorService.createNewElement()"
            [disabled]="!viewData.actionsEnabled"
            [outlined]="true"
            [rounded]="true"
            class="mx-1"
            data-automationId="main-toolbar-new-element-btn"
            icon="pi {{ ICON.ADD }}"
            pTooltip="Neues Element"
          ></p-button>
          <p-button
            (click)="openSettingsDialog()"
            [disabled]="!viewData.actionsEnabled"
            [outlined]="true"
            [rounded]="true"
            class="mx-1"
            data-automationId="cfg-settings-btn"
            icon="pi {{ ICON.SETTINGS }}"
            pTooltip="Einstellungen"
          ></p-button>
          <p-button
            (click)="menu.toggle($event)"
            [disabled]="!viewData.actionsEnabled"
            [outlined]="true"
            [rounded]="true"
            class="mx-1"
            data-automationId="cfg-editmode-menu"
            icon="pi {{ ICON.MENU }}"
          ></p-button>
          <p-menu #menu [model]="editMenuItems" [popup]="true" appendTo="body"></p-menu>
        </ng-container>
      </ng-container>
    </kfd-header-toolbar>
    <div class="flex flex-1 flex-row overflow-hidden">
      <div class="panel center">
        <kfd-dnd-area></kfd-dnd-area>
      </div>
      @if (viewData.editMode) {
        <div [ngClass]="{ collapsed: !viewData.selection && !newEntry }" class="panel right">
          <kfd-cfg-settings *ngIf="newEntry === undefined"></kfd-cfg-settings>
          <kfd-cfg-new-entry (cancel)="cfgEditorService.removeNewElement()" *ngIf="newEntry"></kfd-cfg-new-entry>
        </div>
      }
    </div>
    <div class="toolbar px-2 py-1 flex justify-content-between">
      <div class="flex flex-row">
        <p-button
          class="mx-1"
          data-automationId="preview-stored-values"
          icon="pi {{ ICON.DATABASE }}"
          pTooltip="Werte anzeigen"
          (click)="dndPreviewValuesOverlay.toggle($event)"
          [rounded]="true"
          [outlined]="true"
        ></p-button>
        @if (viewData.editMode) {
          <p-button
            class="mx-1"
            icon="pi {{ ICON.CALCULATION }}"
            pTooltip="Berechnungen verwalten"
            data-automationId="calculation-manager-btn"
            [disabled]="!viewData.actionsEnabled"
            [rounded]="true"
            [outlined]="true"
            (click)="cfgEditorService.openCalculationManagerDialog()"
          ></p-button>
          <!--          <p-button-->
            <!--            class="mx-1"-->
            <!--            icon="pi {{ ICON.CONDITION }}"-->
            <!--            pTooltip="Bedingungen verwalten"-->
            <!--            data-automationId="condition-manager-btn"-->
            <!--            [disabled]="!viewData.actionsEnabled"-->
            <!--            [rounded]="true"-->
            <!--            [outlined]="true"-->
            <!--          ></p-button>-->
          <p-button
            class="mx-1"
            icon="pi {{ ICON.REFRESH }}"
            pTooltip="Vorschaubereich aktualisieren"
            data-automationId="refresh-preview-btn"
            [disabled]="!viewData.actionsEnabled"
            [rounded]="true"
            [outlined]="true"
            (click)="refreshLivePreview()"
          ></p-button>
        }
      </div>
      <div class="flex flex-row">
        @if (viewData.editMode) {
          <div class="flex flex-row align-items-center mx-2">
            <div
              *ngIf="viewData.persistenceState === persistenceState.RUNNING"
              class=""
              pTooltip="Die Änderungen werden gespeichert"
            >
              <i class="pi {{ ICON.SAVE }} text-lg mr-1"></i>
              <i class="pi {{ ICON.SYNC }} text-lg"></i>
            </div>
            <div
              *ngIf="viewData.persistenceState === persistenceState.RETRY"
              class="color-warning"
              pTooltip="Die Änderungen können gerade nicht gespeichert werden"
            >
              <i class="pi {{ ICON.SAVE }} text-lg mr-1"></i>
              <i class="pi {{ ICON.WARN }} text-lg"></i>
            </div>
            <div
              *ngIf="viewData.persistenceState === persistenceState.ERROR"
              class="color-error"
              pTooltip="Beim Speichern ist ein Fehler aufgetreten"
            >
              <i class="pi {{ ICON.SAVE }} text-lg mr-1"></i>
              <i class="pi {{ ICON.WARN }} text-lg"></i>
            </div>
            <div
              *ngIf="viewData.persistenceState === persistenceState.FINISHED"
              class="color-success"
              pTooltip="Alle Änderungen sind gespeichert"
            >
              <i class="pi {{ ICON.SAVE }} text-lg mr-1"></i>
              <i class="pi {{ ICON.ACCEPT }} text-lg"></i>
            </div>
          </div>
        }
      </div>
    </div>
  </div>

  <p-overlayPanel #cfgChecksOverlay styleClass="no-padding large-content">
    <kfd-check-viewer (checkSelection)="cfgChecksOverlay.hide()" [checkResults]="viewData.checkResults" />
  </p-overlayPanel>
} @else {
  <kfd-placeholder-msg
    [infoIcon]="ICON.CONFIGURATIONS"
    class="flex-1 justify-content-center editor-element"
    infoText="Konfiguration wird geladen"
  ></kfd-placeholder-msg>
}

<ng-template #loading></ng-template>

<p-overlayPanel #editHintOverlay styleClass="transparent padding-sm">
  <ng-template pTemplate="content">
    <span class="text-sm">Hier klicken, um in die<br />Bearbeitungs-Ansicht zu wechseln</span>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel
  #dndPreviewValuesOverlay
  (onHide)="dndPreviewValuesOverlayOpen = false"
  (onShow)="dndPreviewValuesOverlayOpen = true"
  styleClass="no-padding large-content"
>
  <p-button
    (click)="dndPreviewValuesOverlay.hide()"
    [outlined]="true"
    [rounded]="true"
    class="value-close-icon"
    data-automationId="close-value-list"
    icon="pi {{ ICON.DECLINE }}"
  ></p-button>
  <h2 class="ml-4">Benutzereingaben</h2>
  <div class="p-fluid m-2 value-list">
    @if (dndPreviewValuesOverlayOpen) {
      <kfd-configuration-value-editor data-automationId="preview-value-list"></kfd-configuration-value-editor>
    }
  </div>
</p-overlayPanel>
