import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export abstract class BaseDialogComponent {
  protected loading = false;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
  ) {}

  close(...args: unknown[]) {
    this.loading = false;
    this.dialogRef.close(...args);
  }
}
