import { Component, OnDestroy } from '@angular/core';
import { CheckResult, CmsConfigurator, CmsGenericEntry, ICON, Is, REQ_PARAMS } from '@kfd/core';
import { ActivatedRoute } from '@angular/router';
import { CfgStateService, PersistenceState } from './cfg-state.service';
import { CmsDialogService } from '../services/cms-dialog.service';
import { EditorHelpDialogComponent } from './dialogs/editor-help-dialog/editor-help-dialog.component';
import { DragDropService } from './dnd-area/drag-drop/drag-drop.service';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { combineLatestWith, Observable, Subject, takeUntil } from 'rxjs';
import { CmsContextService } from '../services/cms-context.service';
import { PreviewDialogComponent } from './dialogs/preview-dialog/preview-dialog.component';
import { NewElementInfo } from './new-entry/new-entry.component';
import { PublishingDialogComponent } from './dialogs/publishing-dialog/publishing-dialog.component';
import { ExportConfigurationDialogComponent } from './dialogs/export-configuration-dialog/export-configuration-dialog.component';
import { CfgEditorErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { MenuItem } from 'primeng/api';
import { RemoveConfigurationDialogComponent } from './dialogs/remove-configuration-dialog/remove-configuration-dialog.component';
import { CfgEditorService } from './cfg-editor.service';
import { ContextRoutingService } from '../services/context-routing.service';
import { CfgSettingsDialogComponent } from './dialogs/cfg-settings-dialog/cfg-settings-dialog.component';
import { IntegrationDialogComponent } from './dialogs/integration-dialog/integration-dialog.component';
import { DataUsageDialogComponent } from './dialogs/data-usage-dialog/data-usage-dialog.component';
import { CfgDataAndTemplateService } from './cfg-data-and-template.service';
import { ConfigurationStateService } from '@kfd/cfg-core';

@Component({
  selector: 'kfd-cfg-editor',
  templateUrl: './cfg-editor.component.html',
  styleUrls: ['./cfg-editor.component.scss'],
})
export class CfgEditorComponent implements OnDestroy {
  protected readonly ICON = ICON;
  protected persistenceState = PersistenceState;
  protected menuItems: MenuItem[] = [
    {
      label: 'Exportieren',
      icon: 'pi pi-fw ' + ICON.EXPORT,
      command: () => this.openExport(),
    },
    {
      label: 'Datennutzung',
      icon: 'pi pi-fw ' + ICON.BASE_DATA,
      automationId: 'cfg-menu-data-usage',
      command: () => this.openDataUsageDialog(),
    },
    {
      label: 'Einbindung',
      icon: 'pi pi-fw ' + ICON.INTEGRATION,
      command: () => this.openIntegrationDialog(),
    },
  ];
  protected editMenuItems: MenuItem[] = [
    ...this.menuItems,
    {
      label: 'Löschen',
      icon: 'pi pi-fw ' + ICON.DELETE,
      automationId: 'cfg-menu-delete',
      command: () => this.openDeleteDialog(),
    },
    {
      label: 'Hilfe',
      icon: 'pi pi-fw ' + ICON.INFO,
      command: () => this.openHelpDialog(),
    },
  ];
  protected dndPreviewValuesOverlayOpen = false;

  protected viewData$: Observable<{
    configuration: CmsConfigurator | undefined;
    selection: CmsGenericEntry | undefined;
    editMode: boolean;
    actionsEnabled: boolean;
    persistenceState: PersistenceState;
    checkResults: CheckResult[];
  }>;

  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly ctx: CmsContextService,
    private readonly cfgStateService: CfgStateService,
    private readonly cmsDialogService: CmsDialogService,
    private readonly cfgDataAndTemplateService: CfgDataAndTemplateService,
    protected readonly contextRouteService: ContextRoutingService,
    protected readonly cfgEditorService: CfgEditorService,
    protected readonly dragDropService: DragDropService,
    protected readonly configurationStateService: ConfigurationStateService,
  ) {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramsMap) => {
      if (paramsMap.has(REQ_PARAMS.PROJECT_ID) && paramsMap.has(REQ_PARAMS.CONFIGURATION_ID)) {
        this.ctx.configuratorId = paramsMap.get(REQ_PARAMS.CONFIGURATION_ID) as string;
        cfgStateService
          .loadConfiguration(
            paramsMap.get(REQ_PARAMS.PROJECT_ID) as string,
            paramsMap.get(REQ_PARAMS.CONFIGURATION_ID) as string,
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            if (paramsMap.has(REQ_PARAMS.ENTRY_ID)) {
              const entryId = paramsMap.get(REQ_PARAMS.ENTRY_ID);
              const entry = Is.id(entryId)
                ? this.cfgStateService.getCfgUtil().getEntryById(entryId)
                : this.cfgStateService.getCfgUtil().getEntryByName(entryId);

              if (entry) {
                this.cfgEditorService.selectEntry(entry.name);
              }
            }
          });
      } else {
        throw new Error('Cannot load configuration without project and configuration id:');
      }
    });

    this.dragDropService.onDrop.pipe(takeUntil(this.destroy$)).subscribe((name) => {
      //close new entry overlay
      this.cfgEditorService.removeNewElement();
      //select dropped entry
      this.cfgEditorService.selectEntry(name);
    });

    this.activatedRoute.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((paramsMap) => {
      if (paramsMap.has('edit')) {
        this.cfgEditorService.editMode = paramsMap.get('edit') === 'true';
      }
    });

    this.viewData$ = this.cfgStateService.onConfigurationChange.pipe(
      combineLatestWith(
        //wait for readiness to ensure templates are available
        this.cfgDataAndTemplateService.isReady,
        this.cfgEditorService.onSelectionChange.pipe(
          tap((entry) => {
            //close new entry overlay
            this.cfgEditorService.removeNewElement();
            if (this.ctx.hasConfiguratorId()) {
              if (entry?._id) {
                this.contextRouteService.toConfigurationEntry(this.ctx.configuratorId, entry._id, false);
              } else {
                this.contextRouteService.toConfiguration(this.ctx.configuratorId, false);
              }
            }
          }),
        ),
        this.cfgEditorService.onEditModeChange,
        this.cfgEditorService.onCreateNewElement.pipe(
          startWith(undefined),
          tap((newElementInfo) => {
            this._newEntry = newElementInfo;
          }),
        ),
        this.cfgStateService.onPersistenceChange.pipe(
          tap((persistenceState) => {
            if (persistenceState === PersistenceState.ERROR) {
              this.cmsDialogService
                .open(
                  CfgEditorErrorDialogComponent,
                  {},
                  {
                    dismissibleMask: false,
                  },
                )
                .onClose.subscribe(() => {
                  window.location.reload();
                });
            }
          }),
        ),
        this.cfgEditorService.checkResults,
      ),
      filter(([configuration, cfgTemplateServiceReady]) => !!configuration && cfgTemplateServiceReady),
      map(([configuration, , selection, editMode, newElement, persistenceState, checkResults]) => ({
        configuration,
        selection,
        editMode,
        actionsEnabled: newElement === undefined && persistenceState === PersistenceState.FINISHED,
        persistenceState,
        checkResults,
      })),
    );
  }

  private _newEntry: NewElementInfo | undefined = {};

  get newEntry(): NewElementInfo | undefined {
    return this._newEntry;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public enableEditMode(): void {
    this.contextRouteService.editMode = true;
  }

  public openPublishing(): void {
    this.cmsDialogService.open(PublishingDialogComponent, {
      projectId: this.ctx.projectId,
      configurationId: this.ctx.configuratorId,
    });
  }

  public openExport(): void {
    this.cmsDialogService.open(ExportConfigurationDialogComponent, {
      projectId: this.ctx.projectId,
      configurationId: this.ctx.configuratorId,
    });
  }

  public openSettingsDialog(): void {
    this.cmsDialogService.open(
      CfgSettingsDialogComponent,
      {},
      {
        fixedHeight: true,
      },
    );
  }

  public openIntegrationDialog(): void {
    this.cmsDialogService.open(IntegrationDialogComponent);
  }

  public openDataUsageDialog(): void {
    this.cmsDialogService.open(DataUsageDialogComponent, {
      configuration: this.cfgStateService.getCfgUtil().getCfg(),
    });
  }

  public openDeleteDialog(): void {
    this.cmsDialogService.open(RemoveConfigurationDialogComponent);
  }

  public openHelpDialog(): void {
    this.cmsDialogService.open(EditorHelpDialogComponent);
  }

  /**
   * Triggers a refresh of the live preview
   */
  protected refreshLivePreview() {
    // this.cfgStateService.refresh().subscribe({
    //   next: () => {
    //     //done
    //   },
    // });
    this.cfgStateService.softRefresh();
  }

  protected openPreview(): void {
    this.cmsDialogService.open(
      PreviewDialogComponent,
      {
        projectId: this.ctx.projectId,
        configurationId: this.ctx.configuratorId,
      },
      {
        fullHeight: true,
      },
    );
  }
}
