<div class="p-2">
  @if (config) {
    <ng-content></ng-content>

    <div class="horizontal-line"></div>
    <div class="p-fluid mt-2">
      <div class="p-field">
        <label> Wert </label>
        <p-dropdown
          (onChange)="updateValueType($event.value)"
          [ngModel]="valueTypeCls"
          [options]="valueOptions"
          appendTo="body"
          data-automationId="config-displaydata-value"
        >
        </p-dropdown>
      </div>
    </div>

    <ng-container [ngSwitch]="valueTypeCls">
      <ng-container *ngSwitchCase="CLS.INPUT_VALUE">
        <ng-container [ngTemplateOutlet]="inputValueConfig"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="CLS.CALCULATION_REF">
        <ng-container [ngTemplateOutlet]="calculationConfig"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="CLS.FIELD_REF">
        <ng-container [ngTemplateOutlet]="fieldRefConfig"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <p-message class="mt-2" severity="info" text="Kein Wert ausgewählt"></p-message>
      </ng-container>
    </ng-container>

    <ng-template #inputValueConfig>
      <div class="flex mt-1">
        <div class="flex-1 flex align-items-center">
          <ng-container *ngIf="config.value?.cls === CLS.INPUT_VALUE">{{ config.value.value }}</ng-container>
          <ng-container *ngIf="config.value?.cls !== CLS.INPUT_VALUE"><i>Kein Wert</i></ng-container>
        </div>
        <div>
          <button
            (click)="inputValueConfigOverlay.toggle($event)"
            class="p-button-rounded p-button-text"
            icon="pi {{ icon.EDIT }}"
            pButton
            type="button"
          ></button>
        </div>
      </div>
      <p-overlayPanel [dismissable]="false" #inputValueConfigOverlay>
        <kfd-data-value-select
          (valueChange)="setDataValue($event); inputValueConfigOverlay.hide()"
          [allowedTypes]="inputValueTypes"
          [showAcceptBtn]="true"
          [value]="config?.value"
        ></kfd-data-value-select>
      </p-overlayPanel>
    </ng-template>

    <ng-template #calculationConfig>
      <div class="flex mt-1">
        <div class="flex-1 flex align-items-center">
          @if (config.value?.cls === CLS.CALCULATION_REF) {
            <span class="ml-2">{{ config.value.name }}</span>
          } @else {
            <i>Kein Berechnung ausgewählt</i>
          }
        </div>
        <div>
          <p-button
            (click)="openCalculationDialog()"
            class="p-button-rounded p-button-text"
            icon="pi {{ icon.EDIT }}"
            [text]="true"
            [rounded]="true"
          ></p-button>
        </div>
      </div>
    </ng-template>

    <ng-template #fieldRefConfig>
      <div class="flex mt-1">
        <div class="flex-1 flex align-items-center">
          <ng-container *ngIf="config.value?.cls === CLS.FIELD_REF">{{ config.value.name | entrylabel }}</ng-container>
          <ng-container *ngIf="config.value?.cls !== CLS.FIELD_REF"><i>Kein Feld</i></ng-container>
        </div>
        <div>
          <button
            (click)="openFieldRefSelection()"
            class="p-button-rounded p-button-text"
            icon="pi {{ icon.EDIT }}"
            pButton
            type="button"
          ></button>
        </div>
      </div>
    </ng-template>
  }
</div>
