<kfd-dialog-layout
  dialogTitle="Berechnungen verwalten"
  [showRejectBtn]="false"
  [showRejectIcon]="true"
  [acceptBtnLabel]="calculationToEdit ? 'Speichern' : 'Beenden'"
  [acceptDisabled]="calculationToEdit && (this.labelNameForm.invalid || calculationToEditValid === false)"
  (accept)="calculationToEdit ? saveChanges() : close()"
  (reject)="calculationToEdit ? (calculationToEdit = undefined) : close()"
>
  @if (calculationToEdit) {
    <div>
      <p-button
        icon="pi {{ ICON.BACK }}"
        label="Abbrechen"
        data-automationId="button-cancel-edit-calculation"
        [rounded]="true"
        [text]="true"
        (click)="calculationToEdit = undefined"
      ></p-button>
    </div>

    <div class="p-fluid mt-2">
      <kfd-label-name-input
        [form]="labelNameForm"
        [manualNameChange]="calculationToEdit.name.length > 0"
      ></kfd-label-name-input>
    </div>

    <h2>Berechnung</h2>
    <kfd-calculation-editor
      [(calculation)]="calculationToEdit.calculation"
      [allowEdit]="true"
      (isValid)="calculationToEditValid = $event"
    ></kfd-calculation-editor>
  } @else {
    @if (cfgCalculationList && cfgCalculationList.length > 0) {
      @for (cfgCalculation of cfgCalculationList; track cfgCalculation.name) {
        <p-panel
          [toggleable]="true"
          [collapsed]="calculationToHighlight !== cfgCalculation.name"
          class="mt-2"
          data-automationTag="calculation-entry"
          [header]="cfgCalculation.label + ' (' + cfgCalculation.name + ')'"
          [attr.data-automationId]="'calculation-entry-' + cfgCalculation.name"
        >
          <!--          <kfd-calculation-viewer [calculation]="cfgCalculation.calculation"></kfd-calculation-viewer>-->
          <kfd-calculation-editor
            [calculation]="cfgCalculation.calculation"
            [allowEdit]="false"
          ></kfd-calculation-editor>

          <div class="flex-1 flex justify-between mt-2">
            <div>
              <kfd-delete-confirm-btn
                confirmLabel="Löschen"
                tooltip="Berechnung entfernen"
                (confirmed)="deleteCalculation(cfgCalculation)"
                data-automationId="button-delete-calculation"
                class="mr-2"
              >
              </kfd-delete-confirm-btn>
              <p-button
                icon="pi {{ ICON.EDIT }}"
                pTooltip="Berechnung bearbeiten"
                label="bearbeiten"
                data-automationId="button-edit-calculation"
                [rounded]="true"
                [text]="true"
                (click)="editCalculation(cfgCalculation)"
              ></p-button>
            </div>

            @if (selectionMode) {
              <p-button
                icon="pi {{ ICON.ACCEPT }}"
                label="Übernehmen"
                data-automationId="button-select-calculation"
                [rounded]="true"
                [text]="true"
                (click)="selectCalculation(cfgCalculation)"
              ></p-button>
            }
          </div>
        </p-panel>
      }

      <div class="mt-2 flex justify-content-center full-width">
        <p-button
          icon="pi {{ ICON.ADD }}"
          label="Neuen Berechnung erstellen"
          [rounded]="true"
          [outlined]="true"
          (click)="createCalculation()"
        ></p-button>
      </div>
    } @else {
      <kfd-placeholder-msg
        data-automationId="calculation-manager-no-entries-msg"
        infoText="Es sind keine Berechnungen vorhanden."
        [infoIcon]="ICON.CALCULATION"
        [btnVisible]="true"
        btnLabel="Neuen Berechnung erstellen"
        (btnClick)="createCalculation()"
      ></kfd-placeholder-msg>
    }
  }
</kfd-dialog-layout>
