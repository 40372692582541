<div *ngIf="config" class="p-fluid p-2">
  <ng-content></ng-content>

  <div class="horizontal-line"></div>
  <div class="p-field">
    <label>Button Label</label>
    <input
      #btnLabelInput
      (change)="change('btnLabel', btnLabelInput.value)"
      [ngModel]="config.btnLabel"
      maxlength="20"
      pInputText
      type="text"
    />
  </div>
</div>
