import { Component, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import {
  BASE_DATA_RESOLVER,
  CmsConfigurator,
  Create,
  ICON,
  ProjectCI,
  projectCiToVariables,
  StringUtil,
  unvTimeout,
} from '@kfd/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CmsContextService } from '../../../services/cms-context.service';
import {
  CFG_CONTEXT_SERVICE,
  CfgContextService,
  ConfigurationService,
  DATA_PROVIDER,
  SettingsService,
} from '@kfd/cfg-core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CalculationService,
  ConfigurationBasedataResolver,
  ConfigurationConditionService,
  ConfigurationFieldRefResolver,
  ConfigurationStateService,
  ConfigurationValidationService,
  EventService,
  PaginationService,
} from '../../../../../../../libs/cfg-core/src/lib/configuration/service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MemoryPersistence } from '../../../../../../../libs/cfg-core/src/lib/configuration/service/persistence/memory.persistence';
import { ConfigurationDataService } from '../../../services/configuration-data.service';
import { ProjectService } from '../../../services/project.service';
import { ApiConfigurationService } from '../../../services/api/api-configuration.service';

@Component({
  selector: 'kfd-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['../../../../../../../libs/cfg-core/src/theme.scss', './preview-dialog.component.scss'],
  providers: [
    SettingsService,
    ConfigurationService,
    CalculationService,
    ConfigurationFieldRefResolver,
    ConfigurationValidationService,
    ConfigurationConditionService,
    PaginationService,
    EventService,
    {
      provide: ConfigurationStateService,
      useFactory: () => {
        return new ConfigurationStateService(new MemoryPersistence());
      },
      deps: [],
    },
    {
      provide: DATA_PROVIDER,
      useClass: ConfigurationDataService,
    },
    {
      provide: CFG_CONTEXT_SERVICE,
      useClass: CfgContextService,
    },
    {
      provide: BASE_DATA_RESOLVER,
      useClass: ConfigurationBasedataResolver,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewDialogComponent extends BaseDialogComponent {
  cmsConfigurator: CmsConfigurator;
  projectId: string;
  configurationId: string;
  protected readonly ICON = ICON;

  constructor(
    private elementRef: ElementRef,
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    private ctx: CmsContextService,
    private projectService: ProjectService,
    private apiConfigurationService: ApiConfigurationService,
    private readonly cfgConfigurationService: ConfigurationService,
    @Inject(CFG_CONTEXT_SERVICE) private readonly cfgCtx: CfgContextService,
  ) {
    super(dialogRef, dialogConfig);
    if (!dialogConfig.data.projectId) {
      throw new Error('No projectId given');
    }
    if (!dialogConfig.data.configurationId) {
      throw new Error('No configurationId given');
    }
    this.projectId = dialogConfig.data.projectId;
    this.configurationId = dialogConfig.data.configurationId;

    this.loading = true;
    // wait to ensure we load latest version
    unvTimeout(() => {
      this.apiConfigurationService.getConfiguration(this.projectId, this.configurationId, false).subscribe({
        next: (cmsConfigurator) => {
          this.cmsConfigurator = cmsConfigurator;
          this.cfgConfigurationService.configuration = cmsConfigurator;
          this.cfgCtx.projectId = this.ctx.projectId;
          this.cfgCtx.configuratorId = this.ctx.configuratorId;
          this.cfgCtx.requestId = StringUtil.rand(5);
          this.loading = false;
        },
      });
    }, 1000);

    this.projectService.getProject(this.ctx.projectId).subscribe((project) => {
      this.applyStyles(project.ci ?? Create.projectCI());
    });
  }

  private applyStyles(projectCI: ProjectCI) {
    const colors = projectCiToVariables(projectCI);
    for (const color of Object.entries(colors)) {
      this.elementRef.nativeElement.style.setProperty(color[0], color[1]);
    }
  }
}
