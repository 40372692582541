<div class="p-fluid">
  <!--  <h2>FieldRef</h2>-->
  <!--  <h3>Default</h3>-->
  <!--  <kfd-field-ref-->
  <!--    [(fieldRef)]="fieldRef"-->
  <!--    [allowProperties]="false"-->
  <!--    [fieldTypes]="INPUT_FIELD_TYPES"-->
  <!--  ></kfd-field-ref>-->
  <!--  @if (fieldRef) {-->
  <!--    <div [innerHTML]="JSON.stringify(fieldRef) | prettyPrintJson" class="align-left"></div>-->
  <!--  }-->

  <!--  <h3>Allow properties</h3>-->
  <!--  <kfd-field-ref-->
  <!--    [(fieldRef)]="fieldRef2"-->
  <!--    [allowProperties]="true"-->
  <!--    [fieldTypes]="INPUT_FIELD_TYPES"-->
  <!--  ></kfd-field-ref>-->
  <!--  @if (fieldRef2) {-->
  <!--    <div [innerHTML]="JSON.stringify(fieldRef2) | prettyPrintJson" class="align-left"></div>-->
  <!--  }-->

  <!--  <h2>FieldRef</h2>-->
  <!--  <h3>Default</h3>-->
  <!--  <kfd-condition-group-->
  <!--    (groupChange)="groupChange($event)"-->
  <!--    (isValid)="isValid = $event"-->
  <!--    [group]="conditionGroup"-->
  <!--  ></kfd-condition-group>-->
</div>

<h2>Calculation</h2>
<p-selectButton
  [options]="calculations"
  (onChange)="calulation = $event.value"
  [multiple]="false"
  optionLabel="label"
  optionValue="calculation"
/>
<div class="p-fluid flex">
  <div class="flex-1 m-2">
    <h3>Edit</h3>
    <kfd-calculation-editor [(calculation)]="calulation"></kfd-calculation-editor>
  </div>
  <div class="flex-1 m-2">
    <h3>View</h3>
    <!--    <kfd-calculation-viewer [calculation]="calulation"></kfd-calculation-viewer>-->
    <kfd-calculation-editor [calculation]="calulation" [allowEdit]="false"></kfd-calculation-editor>
  </div>
</div>
