import { Injectable } from '@angular/core';
import { CmsGenericParentEntry, ConditionalEntry, ICON } from '@kfd/core';
import { MenuItem } from 'primeng/api';
import { CmsDialogService } from '../../services/cms-dialog.service';
import { ContextRoutingService } from '../../services/context-routing.service';
// import { ConditionDialogComponent } from '../../configurator/condition-dialog/condition-dialog.component';
// import { MoveEntryDialogComponent } from '../../configurator/move-entry-dialog/move-entry-dialog.component';
// import { RemoveConfigurationDialogComponent } from '../../configurator/remove-configuration-dialog/remove-configuration-dialog.component';
import {
  RemoveEntryData,
  RemoveEntryDialogComponent,
} from '../dialogs/remove-entry-dialog/remove-entry-dialog.component';
import { ConditionDialogComponent, ModifyConditionData } from '../dialogs/condition-dialog/condition-dialog.component';
import { MoveEntryDialogComponent } from '../dialogs/move-entry-dialog/move-entry-dialog.component';
import { CfgStateService } from '../cfg-state.service';

// const CAN_EDIT = [CLS.PAGE, CLS.QUESTION, CLS.FIELD];
// const CAN_CREATE = [CLS.PAGE, CLS.CONFIGURATION, CLS.QUESTION];
// const CREATES: Record<CLS, CLS> = {
//   [CLS.CONFIGURATION]: CLS.PAGE,
//   [CLS.PAGE]: CLS.QUESTION,
//   [CLS.QUESTION]: CLS.FIELD,
// } as Record<CLS, CLS>;

@Injectable({
  providedIn: 'root',
})
export class EntryMenuService {
  constructor(
    private cmsDialogService: CmsDialogService,
    private cfgStateService: CfgStateService,
    private contextRoutingService: ContextRoutingService,
  ) {}

  public getMenu(entry: CmsGenericParentEntry, parentName?: string): MenuItem[] {
    const menuItems: MenuItem[] = [];

    if (parentName !== undefined) {
      const wrapper = this.cfgStateService.getCfgUtil().getWrapperNodeByName<ConditionalEntry>(entry.name);
      const conditionSize = wrapper.value.condition?.conditions.length ?? 0;
      menuItems.push({
        label: 'Bedingungen',
        icon: 'pi pi-fw ' + ICON.CONDITION,
        badge: conditionSize > 0 ? '' + conditionSize : undefined,
        command: () => this.modifyConditions(entry.name, parentName),
      });

      menuItems.push({
        label: 'Verschieben',
        icon: 'pi ' + ICON.MOVE_Y,
        command: () => this.moveEntry(entry.name),
      });
    }

    menuItems.push({
      label: 'Löschen',
      icon: 'pi pi-fw ' + ICON.DELETE,
      styleClass: 'p-button-warning',
      automationId: 'btn-delete-entry-' + entry.name,
      command: () => {
        this.removeEntry(entry.name);
      },
    });

    return menuItems;
  }

  modifyConditions(entryName: string, parentName: string) {
    const data: ModifyConditionData = {
      parentName,
      childName: entryName,
    };
    this.cmsDialogService.open(ConditionDialogComponent, data);
  }

  moveEntry(entryName: string) {
    this.cmsDialogService.open(MoveEntryDialogComponent, {
      entryName,
    });
  }

  removeEntry(entryName: string) {
    this.cmsDialogService.open(RemoveEntryDialogComponent, {
      entryName,
    } as RemoveEntryData);
  }
}
