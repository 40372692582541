<div class="p-field p-field-checkbox">
  <p-checkbox
    (onChange)="valueChange.emit($event.checked)"
    [ngModel]="value"
    binary="true"
    data-automationId="checkbox-required-field"
    label="Pflichtfeld"
    pTooltip="Pflichtfelder werden markiert und müssen ausgefüllt werden um mit dem Formular fortzufahren."
    tooltipPosition="left"
  ></p-checkbox>
</div>
