import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckError, CheckResult, CLS, ConfigurationChecker, ICON } from '@kfd/core';
import { CfgStateService } from '../../cfg-state.service';
import { CfgEditorService } from '../../cfg-editor.service';
import { CfgDataAndTemplateService } from '../../cfg-data-and-template.service';

@Component({
  selector: 'kfd-check-viewer',
  templateUrl: './check-viewer.component.html',
  styleUrls: ['./check-viewer.component.scss'],
})
export class CheckViewerComponent implements OnInit {
  protected readonly ICON = ICON;
  protected readonly CLS = CLS;
  protected readonly CheckError = CheckError;

  @Input()
  public checkResults: CheckResult[] = [];
  @Output()
  public checkSelection = new EventEmitter<void>();

  constructor(
    private readonly cfgStateService: CfgStateService,
    protected readonly cfgEditorService: CfgEditorService,
    private readonly cfgDataAndTemplateService: CfgDataAndTemplateService,
  ) {}

  ngOnInit() {
    this.updateCheckResults();
  }

  protected showUsage(checkResult: CheckResult): void {
    if (checkResult.cls === CLS.PAGE || checkResult.cls === CLS.FIELD_GROUP || checkResult.cls === CLS.FIELD) {
      this.cfgEditorService.selectEntry(checkResult.name, true);
    }
    if (checkResult.cls === CLS.CALCULATION) {
      this.cfgEditorService.openCalculationManagerDialog(checkResult.name);
    }

    this.checkSelection.emit();
  }

  private updateCheckResults(): void {
    try {
      const cfgChecker = new ConfigurationChecker(this.cfgStateService.getCfgUtil(), this.cfgDataAndTemplateService);
      cfgChecker.check().then((checkResults) => {
        this.checkResults = checkResults;
      });
    } catch (_) {
      this.checkResults = [];
    }
  }
}
