<div class="p-inputgroup">
  <p-dropdown
    [options]="optionList"
    [(ngModel)]="selection"
    [filter]="true"
    filterBy="label"
    [group]="true"
    placeholder="Datensatz auswählen"
  >
    <ng-template pTemplate="selectedItem" let-selectedOption>
      <div class="flex align-items-center gap-2">
        <div>{{ selectedOption.label }}</div>
      </div>
    </ng-template>
    <ng-template let-option pTemplate="item">
      <div class="flex flex-column">
        @if (dataTypes) {
          <p-tag value="{{ option.label }}" />
        } @else {
          <strong>{{ option.label }}</strong>
          @if (option.values) {
            <div class="tag-list mt-2">
              @for (value of option.values; track value.name) {
                <p-tag value="{{ value.label?.text }} {{ value.value }}" />
              }
            </div>
          }
        }
      </div>
    </ng-template>
    <ng-template let-group pTemplate="group">
      <div class="flex flex-column">
        <strong>{{ group.label }}</strong>
        <!--        @if (option.values) {-->
        <!--          <div class="tag-list mt-2">-->
        <!--            @for (value of option.values; track value.name) {-->
        <!--              <p-tag value="{{ value.label?.text }} {{ value.value }}" />-->
        <!--            }-->
        <!--          </div>-->
        <!--        }-->
      </div>
    </ng-template>
  </p-dropdown>
  <p-button (onClick)="select()" icon="pi {{ ICON.ACCEPT }}" pTooltip="Übernehmen"></p-button>
</div>
