<div [kfdBlock]="loading" [loader]="false" class="overflow-auto flex-1">
  @if (viewData$ | async; as viewData) {
    @if (viewData.entry) {
      @if (viewData.entry.isNew) {
        <div class="mx-2" data-automationId="new-entry-form">
          <div class="flex flex-row justify-content-between">
            <div class="white-space-nowrap text-overflow-ellipsis overflow-hidden mt-2">
              {{ viewData.entry.label }}
            </div>
            <div>
              <button
                (click)="delete($any(viewData.entry))"
                class="p-button-rounded p-button-text mx-1"
                icon="pi {{ icon.DELETE }}"
                pButton
                pTooltip="Löschen"
                type="button"
              ></button>
            </div>
          </div>

          <kfd-entry-name (entryChange)="entryChange(viewData.entry.name, $event)"
                          [entry]="viewData.entry"></kfd-entry-name>
        </div>
      } @else {
        <div [attr.data-automationId]="'settings-for-' + viewData.entry.name">
          <div class="flex justify-content-between entry-header p-2">
            <div class="white-space-nowrap text-overflow-ellipsis overflow-hidden mt-2">
              {{ viewData.entry.label }}
            </div>
            <div>
              <div class="white-space-nowrap">
                <p-button
                  (click)="nameSettingsPanel.toggle($event)"
                  [rounded]="true"
                  [text]="true"
                  data-automationId="edit-name-btn"
                  icon="pi {{ icon.EDIT }}"
                ></p-button>
                <p-button
                  (click)="menu.toggle($event)"
                  [rounded]="true"
                  [text]="true"
                  data-automationId="settings-more-options-btn"
                  icon="pi {{ icon.MENU }}"
                ></p-button>
                <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body"></p-menu>
              </div>
            </div>
          </div>

          <ng-container [ngSwitch]="viewData.entry.cls">
            <ng-container *ngSwitchCase="cls.PAGE">
              <kfd-entry-page-settings
                (entryChange)="entryChange(viewData.entry.name, $event)"
                [entry]="viewData.entry"
                [isFirst]="isFirst"
                [isLast]="isLast"
              ></kfd-entry-page-settings>
            </ng-container>
            <ng-container *ngSwitchCase="cls.FIELD_GROUP">
              <kfd-entry-fieldgroup-settings
                (entryChange)="entryChange(viewData.entry.name, $event)"
                [entry]="viewData.entry"
              ></kfd-entry-fieldgroup-settings>
            </ng-container>
            <ng-container *ngSwitchCase="cls.FIELD">
              <kfd-entry-field-settings
                (entryChange)="entryChange(viewData.entry.name, $event)"
                [entry]="viewData.entry"
              ></kfd-entry-field-settings>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="m-2 flex align-items-center justify-content-center">
                <p-message severity="info" text="Keine Einstellungen vorhanden"></p-message>
              </div>
            </ng-container>
          </ng-container>

          <p-overlayPanel #nameSettingsPanel>
            <kfd-entry-name
              (entryChange)="entryChange(viewData.entry.name, $event); nameSettingsPanel.hide()"
              [entry]="viewData.entry"
              style="width: 15rem"
            >
            </kfd-entry-name>
          </p-overlayPanel>
        </div>
      }
    } @else {
      <kfd-empty-msg
        [infoIcon]="icon.SETTINGS"
        class="align-self-center"
        data-automationId="empty-cfg-settings"
        info="Kein Eintrag ausgewählt"
      ></kfd-empty-msg>
    }
  }
</div>
