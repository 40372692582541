import { Injectable } from '@angular/core';
import { Create, FieldRef, FieldRefResolver, FormValue, Is } from '@kfd/core';
import { CfgStateService } from './cfg-state.service';

/**
 * Field reference resolver for the cfg editor
 * resolves no form values - most time we do not have them in the editor - (only default dummy values to determine data type)
 * but resolves calculations
 */
@Injectable({
  providedIn: 'root',
})
export class CfgEditorFieldRefResolver implements FieldRefResolver {
  constructor(private readonly cfgStateService: CfgStateService) {}

  resolve(fieldRef: FieldRef): Promise<FormValue> {
    const field = this.cfgStateService.getCfgUtil().getFieldByName(fieldRef.name, true);
    if (Is.numberField(field)) {
      return Promise.resolve(Create.numericFormValue(0));
    }
    if (Is.dateField(field)) {
      return Promise.resolve(Create.dateFormValue(new Date()));
    }
    return Promise.resolve(Create.emptyFormValue());
  }
}
