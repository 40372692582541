<div [kfdBlock]="loading">
  @if (configuration) {
    <p-card>


      @if (hasErrors) {
        <p-message severity="error"
                   text="Das Formular enthält Fehler und kann deshalb nicht veröffentlich werden"></p-message>
      } @else if (configuration.versions.current > configuration.versions.published) {
        <p-message
          severity="info"
          text="Es gibt Änderungen die noch nicht veröffentlicht wurden"
        >
        </p-message>

      } @else if (configuration.versions.current > configuration.versions.published) {
        <p-message
          severity="success"
          text="Das Formular ist veröffentlicht"
        >
        </p-message>
      }


      <h3>Versionen</h3>
      <kfd-key-value-list
        [data]="{
        Aktuell: {
          automationId: 'current-version',
          type: 'number',
          value: configuration.versions.current
        },
        Entwurf: {
          automationId: 'drafted-version',
          type: 'number',
          value: configuration.versions.drafted
        },
        published: {
          label: 'Veröffentlicht',
          automationId: 'published-version',
          type: 'number',
          value: configuration.versions.published
        }
      }"
        data-automationId="publishing-info-data"
      ></kfd-key-value-list>
    </p-card>

    <p-card class="mt-2" header="Entwurf">
      <ng-container *ngIf="configuration.versions.drafted">
        <div>Aktuelle Entwurf-Version: {{ configuration.versions.drafted }}</div>
      </ng-container>
      <ng-container *ngIf="!configuration.versions.drafted">
        <div>Bisher wurden noch kein Entwurf erstellt.</div>
      </ng-container>

      <p-footer>
        <p-button
          (click)="draft()"
          [disabled]="configuration.versions.current <= configuration.versions.drafted || hasErrors"
          [outlined]="true"
          [rounded]="true"
          [loading]="hasErrors === undefined"
          data-automationId="create-new-draft-btn"
          icon="pi {{ ICON.PUBLISH }}"
          label="Neuer Entwurf"
          pTooltip="Neuen Entwurf erstellen"
        ></p-button>
        <p-button
          (click)="openDraft()"
          [disabled]="!configuration.versions.drafted"
          [outlined]="true"
          [rounded]="true"
          class="ml-2"
          data-automationId="show-draft-cfg-btn"
          icon="pi {{ ICON.SHOW }}"
          pTooltip="Entwurf anzeigen"
        ></p-button>
        <p-button
          (click)="removeDraft()"
          [disabled]="!configuration.versions.drafted"
          [outlined]="true"
          [rounded]="true"
          class="ml-2"
          data-automationId="unpublish-draft-btn"
          icon="pi {{ ICON.DELETE }}"
          pTooltip="Entwurf entfernen"
        ></p-button>
      </p-footer>
    </p-card>

    <p-card class="mt-2" header="Veröffentlicht">
      <ng-container *ngIf="configuration.versions.published">
        <div>Aktuell Publizierte Version: {{ configuration.versions.published }}</div>
      </ng-container>
      <ng-container *ngIf="!configuration.versions.published">
        <div>Bisher wurden noch keine Version publiziert.</div>
      </ng-container>

      <p-footer>
        <p-button
          (click)="publish()"
          [disabled]="configuration.versions.current <= configuration.versions.published || hasErrors"
          [outlined]="true"
          [rounded]="true"
          [loading]="hasErrors === undefined"
          data-automationId="publish-latest-btn"
          icon="pi {{ ICON.PUBLISH }}"
          label="Veröffentlichen"
          pTooltip="Aktuellen Stand veröffentlichen"
        ></p-button>
        <p-button
          (click)="openPublished()"
          [disabled]="!configuration.versions.published"
          [outlined]="true"
          [rounded]="true"
          class="ml-2"
          data-automationId="show-published-cfg-btn"
          icon="pi {{ ICON.SHOW }}"
          pTooltip="Veröffentlichte Konfiguration anzeigen"
        ></p-button>
        <p-button
          (click)="removePublished()"
          [disabled]="!configuration.versions.published"
          [outlined]="true"
          [rounded]="true"
          class="ml-2"
          data-automationId="unpublish-published-btn"
          icon="pi {{ ICON.DELETE }}"
          pTooltip="Publizierung zurückziehen"
        ></p-button>
      </p-footer>
    </p-card>
  }

</div>
