@if (viewData$ | async; as viewData) {
  <div
    [attr.data-automationId]="'cfg-element-' + viewData.field.entry.name"
    class="wrapper"
    [ngClass]="{ selected: viewData.selected, loading: viewData.entryStatus === entryStatusChange.UPDATING }"
    [kfdBlock]="viewData.entryStatus === entryStatusChange.UPDATING"
  >
    <ng-container *ngIf="viewData.field.entry.isNew !== true; else isNew">
      <div class="info-area">
        @if (viewData.field.entry.hide) {
          <i
            class="pi {{ ICON.INVISIBLE }}"
            data-automationId="element-hidden-indicator"
            pTooltip="Das Element ist nicht sichtbar"
          ></i>
        } @else {
          <kfd-condition-viewer [conditionalEntry]="viewData.field"></kfd-condition-viewer>
        }
      </div>

      @if (viewData.selected) {
        <div class="toolbar">
          <div></div>

          @if (viewData.editMode && viewData.selected) {
            <i class="pi drag-initiator {{ ICON.MOVE }}" #dragInitiator></i>
          }
        </div>
      }

      <div
        (tap)="select()"
        [interactOptions]="{ eventPropagation: false }"
        class="content"
        data-automationId="element-selector"
        interactPointer
      >
        <div
          [ngClass]="{ 'hide-label': viewData.field.entry.hideLabel }"
          class="element-label"
          data-automationId="element-label"
        >
          <i class="pi {{ viewData.entryIcon }}"></i>
          <span class="ml-1 label">{{ viewData.field.entry.label }}</span>
          <span *ngIf="viewData.field.entry.config?.required">*</span>
        </div>

        <kfd-configurator-field [demo]="true" [field]="viewData.field.entry"></kfd-configurator-field>
        @if (!viewData.selected) {
          <div class="selection-overlay" data-automationId="selection-overlay" (tap)="select()" interactPointer></div>
        }
      </div>

      <!--          <div *ngIf="dndEnabled.enabled === true" class="dnd-overlay"></div>-->
      <div
        (tap)="select()"
        *ngIf="!viewData.field.entry._id"
        [interactOptions]="{ eventPropagation: false }"
        class="disabled-overlay"
        interactPointer
      ></div>
    </ng-container>
  </div>
  <!--  </ng-container>-->

  <ng-template #isNew>
    <kfd-placeholder-msg
      (tap)="select()"
      [infoIcon]="viewData.entryIcon"
      infoText="Das Feld wurde noch nicht gespeichert"
      [interactOptions]="{ eventPropagation: false }"
      interactPointer
    ></kfd-placeholder-msg>
  </ng-template>
}
