import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalculationFunction, Create, FunctionCommand, Is } from '@kfd/core';
import { functionCommandList } from '../../../shared/global';

@Component({
  selector: 'kfd-calculation-fn',
  templateUrl: './calculation-fn.component.html',
  styleUrls: ['./calculation-fn.component.scss'],
})
export class CalculationFnComponent {
  @Output() public fnChange: EventEmitter<CalculationFunction> = new EventEmitter<CalculationFunction>();
  public functionCommand = FunctionCommand;
  public functionCommandList = functionCommandList;
  private calculationFunction: CalculationFunction = Create.calculationFunction();

  public get fn(): CalculationFunction {
    return this.calculationFunction;
  }

  @Input()
  public set fn(fn: CalculationFunction) {
    if (!Is.calculationFunction(fn)) {
      return;
    }
    this.calculationFunction = fn;
  }

  public change(): void {
    this.fnChange.emit(this.calculationFunction);
  }
}
