import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CfgStateService } from '../../cfg-state.service';
import { CLS, CmsChildWrapper, ICON, MovePosition, ObjectUtil } from '@kfd/core';
import { v4 as uuidv4 } from 'uuid';

export interface DragElementInfo {
  elementName: string;
  parentName?: string;
  position?: number;
  cls: CLS;
  icon?: ICON;
}

@Injectable({
  providedIn: 'root',
})
export class DragDropService {
  public onDrop = new Subject<string>();

  constructor(private readonly cfgStateService: CfgStateService) {}

  private _onDndActive = new BehaviorSubject<string | undefined>(undefined);

  public get onDndActive(): Observable<string | undefined> {
    return new Observable((subscriber) => {
      this._onDndActive.subscribe((active) => {
        subscriber.next(active);
      });
    });
  }

  private _dragElementInfo: DragElementInfo | undefined = undefined;

  public get dragElementInfo(): DragElementInfo | undefined {
    return this._dragElementInfo;
  }

  private _dropElementInfo: MovePosition | undefined;

  get dropElementInfo(): MovePosition | undefined {
    return this._dropElementInfo;
  }

  set dropElementInfo(value: MovePosition | undefined) {
    this._dropElementInfo = value;
  }

  public startDragging(dragElementInfo: DragElementInfo) {
    // if (this.dndEnabled === false) {
    //   return;
    // }
    if (dragElementInfo.elementName === this._dragElementInfo?.elementName) {
      return;
    }
    this._dragElementInfo = dragElementInfo;
    this._onDndActive.next(dragElementInfo.elementName);
  }

  public stopDragging() {
    this._dragElementInfo = undefined;
    this._dropElementInfo = undefined;
    // this.dndEnabled = false;
    this._onDndActive.next(undefined);
  }

  public onDropzoneStatusChange(acceptableCls: CLS[], parent: string, position: number): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this._onDndActive.subscribe((active) => {
        if (active === undefined) {
          subscriber.next(false);
        } else {
          subscriber.next(this.canBeDropped(acceptableCls, parent, position));
        }
      });
    });
  }

  public drop(cmsChildWrapper?: CmsChildWrapper): boolean {
    if (!this.dragElementInfo || !this.dropElementInfo) {
      return false;
    }

    // create a new entry
    if (cmsChildWrapper !== undefined) {
      const newEntry = ObjectUtil.clone(cmsChildWrapper);
      if (!newEntry.entry.name) {
        newEntry.entry.name = uuidv4();
      }
      this.cfgStateService.createEntry(newEntry, this.dropElementInfo);
      this.onDrop.next(newEntry.entry.name);
      this.stopDragging();
      return true;
    } else {
      // move an existing entry
      this.cfgStateService.moveEntry(this.dragElementInfo.elementName, this.dropElementInfo);
      this.onDrop.next(this.dragElementInfo.elementName);
      this.stopDragging();
      return true;
    }
  }

  protected canBeDropped(acceptableCls: CLS[], parentName: string, position: number): boolean {
    if (this.dragElementInfo === undefined) {
      return false;
    }
    if (acceptableCls.indexOf(this.dragElementInfo.cls) === -1) {
      return false;
    }
    // console.log(this.dragElementInfo.position, position)
    if (this.dragElementInfo.parentName === parentName) {
      if (this.dragElementInfo.position === position || this.dragElementInfo.position === position - 1) {
        return false;
      }
    }
    return true;
  }
}
