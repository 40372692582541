<div *ngIf="config" class="p-fluid p-2">
  <ng-content></ng-content>

  <kfd-required-cfg-setting (valueChange)="change('required', $event)" [value]="config.required" class="mt-2">
  </kfd-required-cfg-setting>

  <kfd-disabled-cfg-setting (valueChange)="change('disabled', $event)" [value]="config.disabled" class="mt-2">
  </kfd-disabled-cfg-setting>

  <div class="horizontal-line"></div>

  <div class="p-field">
    <label>Nachkommastellen</label>
    <p-inputNumber
      #digitsValue
      (onInput)="change('digits', digitsValue.value)"
      [ngModel]="config.digits"
      [showButtons]="true"
      data-automationId="numeric-config-digits"
    >
    </p-inputNumber>
  </div>

  <div class="p-field">
    <label>Schrittweite</label>
    <p-inputNumber
      #stepValue
      (onInput)="change('step', stepValue.value)"
      [minFractionDigits]="config.digits ?? 0"
      [ngModel]="config.step"
      [showButtons]="true"
      data-automationId="numeric-config-step"
      locale="de-DE"
      mode="decimal"
    >
    </p-inputNumber>
  </div>

  <kfd-toggle-setting
    (disable)="change('default', undefined)"
    (enable)="changeValue('default', defaultValue)"
    [object]="config"
    [property]="'default'"
    class="p-field"
    data-automationId="numeric-config-default"
    label="Standardwert verwenden"
  >
    <kfd-data-value
      (valueChange)="changeValue('default', $event)"
      [properties]="{ showButtons: true, minFractionDigits: config.digits }"
      [type]="dataValueType.NUMERIC"
      [value]="config.default"
    ></kfd-data-value>
  </kfd-toggle-setting>
  <kfd-toggle-setting
    (disable)="change('min', undefined)"
    (enable)="changeValue('min', defaultValue)"
    [object]="config"
    [property]="'min'"
    class="p-field"
    data-automationId="numeric-config-min"
    label="Minimalwert "
  >
    <kfd-data-value
      (valueChange)="changeValue('min', $event)"
      [properties]="{ showButtons: true, minFractionDigits: config.digits }"
      [type]="dataValueType.NUMERIC"
      [value]="config.min"
    ></kfd-data-value>
  </kfd-toggle-setting>
  <kfd-toggle-setting
    (disable)="change('max', undefined)"
    (enable)="changeValue('max', defaultMaxValue)"
    [object]="config"
    [property]="'max'"
    class="p-field"
    data-automationId="numeric-config-max"
    label="Maximalwert"
  >
    <kfd-data-value
      (valueChange)="changeValue('max', $event)"
      [properties]="{ showButtons: true, minFractionDigits: config.digits }"
      [type]="dataValueType.NUMERIC"
      [value]="config.max"
    ></kfd-data-value>
  </kfd-toggle-setting>

  <div class="p-field">
    <label>Einheit</label>
    <input
      #unitInput
      (change)="change('unit', unitInput.value)"
      [ngModel]="config.unit"
      data-automationId="numeric-config-unit"
      maxlength="20"
      pInputText
      pTooltip="Die Einheit wird nach dem Eingabewert angezeigt, zb '€' oder 'cm'."
      type="text"
    />
  </div>
  <div class="p-field p-field-checkbox">
    <p-checkbox
      (onChange)="change('showButtons', $event.checked)"
      [ngModel]="config.showButtons"
      binary="true"
      data-automationId="numeric-config-buttons"
      label="Buttons anzeigen"
    ></p-checkbox>
  </div>
</div>
