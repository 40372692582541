<div class="p-field p-field-checkbox">
  <p-checkbox
    (onChange)="valueChange.emit($event.checked)"
    [ngModel]="value"
    binary="true"
    label="Eingaben deaktivieren"
    pTooltip="Deaktiviert das manuelle Eingeben von Werten. Dies kann sinnvoll sein, wenn das Feld automatisch ausgefüllt wird."
    tooltipPosition="left"
  ></p-checkbox>
</div>
