import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '../../../../environments/environment';
import {
  CFG_CONTEXT_SERVICE,
  CfgContextService,
  DATA_PROVIDER,
  MemoryPersistence,
  PERSISTENCE_TOKEN,
} from '@kfd/cfg-core';
import { BASE_DATA_RESOLVER, CFG_APP_ROUTES, EndpointService, ICON } from '@kfd/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigurationBasedataResolver } from '../../../../../../../libs/cfg-core/src/lib/configuration/service/configuration-basedata-resolver';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigurationStateService } from '../../../../../../../libs/cfg-core/src/lib/configuration/service/configuration-state.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SessionPersistence } from '../../../../../../../libs/cfg-core/src/lib/configuration/service/persistence/session.persistence';
import { v4 as uuidv4 } from 'uuid';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';
import { ConfigurationDataService } from '@kfd/web-core';

@Component({
  selector: 'kfd-show-configuration-dialog',
  templateUrl: './show-configuration-dialog.component.html',
  styleUrls: ['./show-configuration-dialog.component.scss'],
  providers: [
    {
      provide: DATA_PROVIDER,
      useClass: ConfigurationDataService,
    },
    {
      provide: CFG_CONTEXT_SERVICE,
      useClass: CfgContextService,
    },
    {
      provide: PERSISTENCE_TOKEN,
      useFactory: () => {
        if (SessionPersistence.available()) {
          const persistence = new SessionPersistence();
          persistence.useKey(uuidv4());
          return persistence;
        } else {
          return new MemoryPersistence();
        }
      },
    },
    {
      provide: BASE_DATA_RESOLVER,
      useClass: ConfigurationBasedataResolver,
    },
    ConfigurationStateService,
  ],
})
export class ShowConfigurationDialogComponent extends BaseDialogComponent {
  icon = ICON;
  projectId: string | undefined;
  configurationId: string | undefined;
  preview = false;
  showCfg = true;
  externalLink: string | undefined;
  disablePreview = false;
  disablePublished = false;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    private configurationStateService: ConfigurationStateService,
  ) {
    super(dialogRef, dialogConfig);
    if (dialogConfig.data.projectId) {
      this.projectId = dialogConfig.data.projectId;
    }
    if (dialogConfig.data.configurationId) {
      this.configurationId = dialogConfig.data.configurationId;
    }
    if (dialogConfig.data.preview) {
      this.preview = !!dialogConfig.data.preview;
    }
    if (dialogConfig.data.versions) {
      (this.disablePreview = !dialogConfig.data.versions.drafted),
        (this.disablePublished = !dialogConfig.data.versions.published);
      if (this.disablePreview) {
        this.preview = false;
      }
      if (this.disablePublished) {
        this.preview = true;
      }
    }
    this.setExternalLink();
  }

  previewChange(preview: boolean) {
    this.preview = preview;
    this.showCfg = false;
    this.setExternalLink();
    window.setTimeout(() => {
      this.showCfg = true;
    });
  }

  reset() {
    this.configurationStateService.removeAllValues();
    this.showCfg = false;
    window.setTimeout(() => {
      this.showCfg = true;
    });
  }

  setExternalLink() {
    if (this.projectId && this.configurationId) {
      const route = this.preview
        ? CFG_APP_ROUTES.project.configuration.previewForm
        : CFG_APP_ROUTES.project.configuration.form;
      this.externalLink =
        environment.apps.cfg + EndpointService.relativePath(route, [this.projectId, this.configurationId]);
      // this.externalLink = `${environment.apps.cfg}/configurator/${this.projectId}/${this.configurationId}${preview}`;
    } else {
      this.externalLink = undefined;
    }
  }
}
