import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { KFD_WEB_APP_ROUTES } from '@kfd/core';

@Component({
  selector: 'kfd-fullscreen-layout',
  templateUrl: './fullscreen-layout.component.html',
  styleUrls: ['./fullscreen-layout.component.scss'],
})
export class FullscreenLayoutComponent {
  protected readonly environment = environment;
  protected readonly KFD_WEB_APP_ROUTES = KFD_WEB_APP_ROUTES;
}
