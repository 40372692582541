import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CONDITIONAL_OPERATOR, ConditionGroup, Create, DATA_VALUE_TYPE, ICON, LOGICAL_OPERATOR } from '@kfd/core';
import { ConditionComponent } from '../condition/condition.component';

@Component({
  selector: 'kfd-condition-group',
  templateUrl: './condition-group.component.html',
  styleUrls: ['./condition-group.component.scss'],
})
export class ConditionGroupComponent {
  @Output()
  public isValid = new EventEmitter<boolean>();

  @Output()
  public groupChange = new EventEmitter<ConditionGroup>();

  protected readonly ICON = ICON;
  protected readonly LOGICAL_OPERATOR = LOGICAL_OPERATOR;
  protected invalidCondition = false;
  protected conditionGroup: ConditionGroup = Create.conditionGroup();

  @Input()
  set group(conditionGroup: ConditionGroup) {
    if (!conditionGroup) {
      this.setEmptyGroup();
      return;
    }
    this.conditionGroup = conditionGroup;
  }

  remove(index: number) {
    this.conditionGroup.conditions.splice(index, 1);
    this.conditionChange();
  }

  conditionChange() {
    this.checkValidity();
    if (this.isValid) {
      this.groupChange.emit(this.conditionGroup);
    }
  }

  checkValidity() {
    let invalidCondition = false;
    for (const condition of this.conditionGroup.conditions) {
      if (!ConditionComponent.isConditionValid(condition)) {
        invalidCondition = true;
      }
    }
    this.invalidCondition = invalidCondition;
    this.isValid.emit(!this.invalidCondition);
  }

  addEmptyCondition() {
    if (!this.conditionGroup) {
      this.setEmptyGroup();
    }
    this.conditionGroup.conditions.push(
      Create.condition(
        Create.fieldRef(),
        CONDITIONAL_OPERATOR.EMPTY,
        Create.createDefaultInputValue(DATA_VALUE_TYPE.STRING),
      ),
    );
    this.conditionChange();
  }

  private setEmptyGroup() {
    this.conditionGroup = Create.conditionGroup();
  }
}
