<div class="p-2">
  <ng-container *ngIf="config">
    <ng-content></ng-content>

    <kfd-required-cfg-setting (valueChange)="change('required', $event)" [value]="config.required" class="mt-2">
    </kfd-required-cfg-setting>

    <kfd-disabled-cfg-setting (valueChange)="change('disabled', $event)" [value]="config.disabled" class="mt-2">
    </kfd-disabled-cfg-setting>

    <div class="horizontal-line"></div>

    <div class="p-field">
      <p-checkbox
        (onChange)="change('inline', $event.checked)"
        [ngModel]="config.inline"
        binary="true"
        label="Kalender immer anzeigen"
      ></p-checkbox>
    </div>

    <div class="p-field">
      <p-checkbox
        (onChange)="change('showIcon', $event.checked)"
        [disabled]="config.inline === true"
        [ngModel]="config.showIcon !== false"
        binary="true"
        label="Kalender Icon anzeigen"
      ></p-checkbox>
    </div>

    <div class="p-field">
      <p-checkbox
        (onChange)="change('showOnFocus', $event.checked)"
        [disabled]="config.inline === true"
        [ngModel]="config.showOnFocus"
        binary="true"
        label="Kalender automatisch öffnen"
      ></p-checkbox>
    </div>

    <div class="horizontal-line"></div>

    <kfd-toggle-setting
      (disable)="change('default', undefined)"
      (enable)="change('default', defaultDateValue)"
      [object]="config"
      [property]="'default'"
      class="p-field"
      data-automationId="config-date-default"
      label="Standardwert"
    >
      <kfd-editor-date-input
        (dateValueChange)="change('default', $event)"
        [dateValue]="config.default"
      ></kfd-editor-date-input>
    </kfd-toggle-setting>

    <kfd-toggle-setting
      (disable)="change('min', undefined)"
      (enable)="change('min', defaultDateValue)"
      [object]="config"
      [property]="'min'"
      class="p-field"
      data-automationId="config-date-min"
      label="Minimalwert"
    >
      <kfd-editor-date-input (dateValueChange)="change('min', $event)" [dateValue]="config.min"></kfd-editor-date-input>
    </kfd-toggle-setting>

    <kfd-toggle-setting
      (disable)="change('max', undefined)"
      (enable)="change('max', defaultDateValue)"
      [object]="config"
      [property]="'max'"
      class="p-field"
      data-automationId="config-date-max"
      label="Maximalwert"
    >
      <kfd-editor-date-input (dateValueChange)="change('max', $event)" [dateValue]="config.max"></kfd-editor-date-input>
    </kfd-toggle-setting>

    <div class="horizontal-line"></div>

    <kfd-toggle-setting
      (disable)="change('disabledDays', undefined)"
      (enable)="change('disabledDays', [])"
      [object]="config"
      [property]="'disabledDays'"
      class="p-field"
      label="Wochentage auswählen"
      pTooltip="Ermöglicht die manuelle Auswahl, welche Wochentage selektierbar sind."
    >
      <table class="disabled-week-days mt-1 no-border">
        <tr>
          <td>Mo</td>
          <td>Di</td>
          <td>Mi</td>
          <td>Do</td>
          <td>Fr</td>
          <td>Sa</td>
          <td>So</td>
        </tr>
        <tr>
          <td>
            <p-checkbox
              (onChange)="toggleDisabledDay(1)"
              [binary]="true"
              [ngModel]="this.config.disabledDays?.indexOf(1) === -1"
              binary="true"
            ></p-checkbox>
          </td>
          <td>
            <p-checkbox
              (onChange)="toggleDisabledDay(2)"
              [binary]="true"
              [ngModel]="this.config.disabledDays?.indexOf(2) === -1"
            ></p-checkbox>
          </td>
          <td>
            <p-checkbox
              (onChange)="toggleDisabledDay(3)"
              [binary]="true"
              [ngModel]="this.config.disabledDays?.indexOf(3) === -1"
            ></p-checkbox>
          </td>
          <td>
            <p-checkbox
              (onChange)="toggleDisabledDay(4)"
              [binary]="true"
              [ngModel]="this.config.disabledDays?.indexOf(4) === -1"
            ></p-checkbox>
          </td>
          <td>
            <p-checkbox
              (onChange)="toggleDisabledDay(5)"
              [binary]="true"
              [ngModel]="this.config.disabledDays?.indexOf(5) === -1"
            ></p-checkbox>
          </td>
          <td>
            <p-checkbox
              (onChange)="toggleDisabledDay(6)"
              [binary]="true"
              [ngModel]="this.config.disabledDays?.indexOf(6) === -1"
            ></p-checkbox>
          </td>
          <td>
            <p-checkbox
              (onChange)="toggleDisabledDay(0)"
              [binary]="true"
              [ngModel]="this.config.disabledDays?.indexOf(0) === -1"
            ></p-checkbox>
          </td>
        </tr>
      </table>
    </kfd-toggle-setting>
  </ng-container>
</div>
