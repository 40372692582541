@if (!waitForConfirm) {
  <p-button
    [label]="label"
    [rounded]="true"
    [outlined]="btnOutlined"
    [text]="btnText"
    [disabled]="disabled"
    [icon]="'pi ' + icon"
    class="{{ btnClass }}"
    [attr.data-automationId]="dataId + '-prepare'"
    iconPos="left"
    [pTooltip]="tooltip"
    (click)="deleteClicked()"
  ></p-button>
} @else {
  <p-button
    [label]="confirmLabel"
    [rounded]="true"
    [outlined]="btnOutlined"
    class="{{ confirmClass }}"
    [attr.data-automationId]="dataId + '-confirm'"
    icon="pi pi-check"
    iconPos="right"
    (click)="confirmed.emit($event)"
  ></p-button>
}
