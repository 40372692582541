import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseDataRef, CmsSelectionData, Create, DATA_VALUE_TYPE, ICON } from '@kfd/core';
import { CfgDataAndTemplateService } from '../../cfg-data-and-template.service';

@Component({
  selector: 'kfd-base-data-ref',
  templateUrl: './base-data-ref.component.html',
  styleUrl: './base-data-ref.component.scss',
})
export class BaseDataRefComponent implements OnInit {
  /**
   * Data types (of values) that are allowed to be selected
   * if undefined all data types are allowed and entries without values
   */
  private _dataTypes: DATA_VALUE_TYPE[] | undefined;

  protected _baseDataRef: BaseDataRef | undefined;
  protected readonly ICON = ICON;
  protected optionList: CmsSelectionData[] = [];
  protected selection: BaseDataRef | undefined;

  @Output()
  public baseDataRefChange = new EventEmitter<BaseDataRef>();

  constructor(private readonly cfgDataAndTplService: CfgDataAndTemplateService) {}

  public ngOnInit(): void {
    this.loadBaseDataEntries();
  }

  public get baseDataRef(): BaseDataRef | undefined {
    return this._baseDataRef;
  }

  @Input()
  public set baseDataRef(value: BaseDataRef | undefined) {
    this._baseDataRef = value;
    const baseDateEntry = this.cfgDataAndTplService.getBaseDataEntries().find((entry) => entry.name === value?.name);
    if (baseDateEntry) {
      this.selection = Create.baseDataRef(baseDateEntry.name);
    }
  }

  public get dataTypes(): DATA_VALUE_TYPE[] | undefined {
    return this._dataTypes;
  }

  @Input()
  public set dataTypes(value: DATA_VALUE_TYPE[] | undefined) {
    this._dataTypes = value;
    this.loadBaseDataEntries();
  }

  protected loadBaseDataEntries(): void {
    this.optionList = this.cfgDataAndTplService.getBaseDataEntries().filter((entry) => {
      if (!this.dataTypes) {
        return true;
      }
      if (!entry.values) {
        return false;
      }
      return entry.values.some((value) => this.dataTypes.includes(value.type));
    });
  }

  protected select() {
    if (!this.selection) {
      return;
    }
    this.baseDataRefChange.emit(Create.baseDataRef(this.selection.name));
  }
}
