<kfd-dialog-layout (accept)="save()" (reject)="close()" acceptBtnLabel="Speichern" dialogTitle="Feld Referenz">
  <div class="p-fluid">
    <kfd-field-ref
      [(fieldRef)]="fieldRef"
      [allowDefaultValue]="true"
      [allowProperties]="allowProperties"
      [fieldTypes]="fieldTypes"
      [inline]="true"
    ></kfd-field-ref>
  </div>
</kfd-dialog-layout>
