<div class="p-field p-field-checkbox">
  <p-checkbox
    (onChange)="valueChange.emit($event.checked)"
    [ngModel]="value"
    binary="true"
    data-automationId="hide-field-checkbox"
    label="Element ausblenden"
    pTooltip="Das Element ist nicht für den Benutzer sichtbar."
    tooltipPosition="left"
  ></p-checkbox>
</div>
