import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ICON } from '@kfd/core';

@Component({
  selector: 'kfd-delete-confirm-btn',
  templateUrl: './delete-confirm-btn.component.html',
  styleUrls: ['./delete-confirm-btn.component.scss'],
})
export class DeleteConfirmBtnComponent {
  @Output() confirmed: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Input()
  icon = ICON.DELETE;

  @Input()
  btnClass = 'p-button-primary';

  @Input()
  confirmClass = 'p-button-danger';

  @Input()
  label = '';

  @Input()
  confirmLabel = '';

  @Input()
  dataId = '';

  @Input()
  btnOutlined = false;

  @Input()
  btnText = true;

  @Input()
  tooltip = 'Löschen';

  @Input()
  disabled = false;

  waitForConfirm = false;

  constructor(private ref: ChangeDetectorRef) {}

  deleteClicked() {
    this.waitForConfirm = true;
    window.setTimeout(() => {
      this.waitForConfirm = false;
      this.ref.detectChanges();
    }, 3000);
  }
}
