import { Injectable } from '@angular/core';
import { Calculation, CalculationRef, CalculationRefResolver } from '@kfd/core';
import { CfgStateService } from './cfg-state.service';

/**
 * Field reference resolver for the cfg editor
 * resolves no form values - most time we do not have them in the editor - (only default dummy values to determine data type)
 * but resolves calculations
 */
@Injectable({
  providedIn: 'root',
})
export class CfgEditorCalculationRefResolver implements CalculationRefResolver {
  constructor(private readonly cfgStateService: CfgStateService) {}

  resolve(calculationRef: CalculationRef): Promise<Calculation | undefined> {
    return Promise.resolve(this.cfgStateService.getCfgUtil().getCalculation(calculationRef.name)).then(
      (cfgCalculation) => (cfgCalculation ? cfgCalculation.calculation : undefined),
    );
  }
}
