import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  CalculationRef,
  CLS,
  Create,
  DATA_VALUE_TYPE,
  DisplayDataFieldConfig,
  FIELD_TYPES,
  FieldRef,
  INPUT_VALUE_TYPES,
  InputValue,
  Is,
  SelectOption,
  VALUE_FIELD_TYPES,
} from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';
import { CmsDialogService } from '../../../../services/cms-dialog.service';
import { FieldRefDialogComponent } from '../../../dialogs/field-ref-dialog/field-ref-dialog.component';
import { CalculationManagerComponent } from '../../../dialogs/calculation-manager/calculation-manager.component';
import { CfgStateService } from '../../../cfg-state.service';

@Component({
  selector: 'kfd-field-settings-details-displaydata',
  templateUrl: './display-data.component.html',
  styleUrls: ['./display-data.component.scss'],
})
export class DisplayDataEntryFieldSettingsComponent extends BaseEntryFieldSettingsComponent<DisplayDataFieldConfig> {
  protected valueOptions: SelectOption[] = [
    {
      label: 'Fester Wert',
      description: 'Statischer Wert, der fest eingetragen wird',
      value: CLS.INPUT_VALUE,
    },
    {
      label: 'Kalkulation',
      description: 'Ergebnis einer Berechnung',
      value: CLS.CALCULATION_REF,
    },
    {
      label: 'Feld-Referenz',
      description: 'Benutzer-Eingabe eines Feldes',
      value: CLS.FIELD_REF,
    },
  ];

  protected readonly CLS = CLS;
  protected readonly inputValueTypes = INPUT_VALUE_TYPES;

  protected valueTypeCls: CLS | undefined;

  public constructor(
    protected cd: ChangeDetectorRef,
    protected readonly cmsDialogService: CmsDialogService,
    private readonly cfgStateService: CfgStateService,
  ) {
    super(cd);
  }

  public get config(): DisplayDataFieldConfig | undefined {
    return this._config;
  }

  @Input()
  public set config(value: DisplayDataFieldConfig | undefined) {
    super.config = value;
    if (this._config?.value) {
      this.valueTypeCls = this._config.value.cls;
    } else {
      this.valueTypeCls = undefined;
    }
  }

  public getType(): FIELD_TYPES {
    return FIELD_TYPES.DISPLAY_DATA;
  }

  public updateValueType(cls: CLS.INPUT_VALUE | CLS.FIELD_REF | CLS.CALCULATION): void {
    this.valueTypeCls = cls;
    if (cls === CLS.CALCULATION) {
      this.config.dataType = DATA_VALUE_TYPE.NUMERIC;
    } else {
      this.config.dataType = DATA_VALUE_TYPE.VOID;
    }
  }

  public openCalculationDialog(): void {
    this.cmsDialogService
      .open(CalculationManagerComponent, {
        calculation: Is.calculationRef(this._config.value) ? this._config.value : undefined,
        selectionMode: true,
      })
      .onClose.subscribe((calculationRef: CalculationRef | undefined) => {
        if (Is.calculationRef(calculationRef)) {
          this.change('value', calculationRef);
        }

        //because we do not know about changes in the calculation manager, we need to refresh the view
        //todo optimize and only refresh if the calculation was changed and/or only relevant fields
        this.cfgStateService.softRefresh();
      });
  }

  protected openFieldRefSelection(): void {
    this.cmsDialogService
      .open(FieldRefDialogComponent, {
        fieldRef: Is.fieldRef(this._config.value) ? this._config.value : Create.fieldRef(),
        fieldTypes: VALUE_FIELD_TYPES,
        allowProperties: true,
      })
      .onClose.subscribe((fieldRef: FieldRef | undefined) => {
        if (Is.fieldRef(fieldRef)) {
          this.change('value', fieldRef);
        }
      });
  }

  protected setDataValue(value: InputValue): void {
    if (!Is.inputValue(value)) {
      return;
    }
    this.config.dataType = value.type;
    this.change('value', value);
  }
}
