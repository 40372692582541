import { Component } from '@angular/core';
import {
  CalcOperation,
  Calculation,
  CfgCalculation,
  CLS,
  CmsConfigurator,
  Configuration,
  Create,
  DATA_VALUE_TYPE,
  FieldRef,
  INPUT_FIELD_TYPES,
} from '@kfd/core';
import { CfgStateService } from '../cfg-state.service';

@Component({
  selector: 'kfd-internal',
  templateUrl: './internal.component.html',
  styleUrl: './internal.component.scss',
})
export class InternalComponent {
  protected readonly INPUT_FIELD_TYPES = INPUT_FIELD_TYPES;
  protected fieldRef: FieldRef | undefined;
  protected fieldRef2: FieldRef | undefined;
  protected readonly JSON = JSON;

  protected calculations: CfgCalculation[] = [
    {
      label: 'Leer',
      name: 'empty',
      calculation: Create.calculation(),
    },
    {
      label: 'Simple',
      name: 'simple',
      calculation: Create.calculation(
        [
          Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 5),
          Create.calculation(
            [Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 1), Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 2)],
            CalcOperation.ADD,
          ),
        ],
        CalcOperation.MUL,
      ),
    },
    {
      label: 'Extended',
      name: 'extended',
      calculation: Create.calculation(
        [
          {
            cls: CLS.CALCULATION,
            op: CalcOperation.ADD,
            label: Create.valueLabel('Zwischensumme'),
            alias: 'prepared-sum',
            excludeFromResult: true,
            params: [
              Create.baseDataValueRef('mwst'),
              Create.fieldRef('test'),
              Create.calculation(
                [Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 64), Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 16)],
                CalcOperation.DIV,
              ),
            ],
          },
          Create.calculation(
            [Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 64), Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 16)],
            CalcOperation.DIV,
          ),
          Create.calculation(
            [Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 2), Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 2)],
            CalcOperation.MUL,
          ),
          Create.calculation([Create.aliasValue('prepared-sum'), Create.baseDataValueRef('mwst')], CalcOperation.SUB),
        ],
        CalcOperation.ADD,
      ),
    },
    {
      label: 'Charge Costs',
      name: 'charge-costs',
      calculation: Create.calculation(
        // result = 25,2
        [
          Create.calculation(
            // result = 2520
            [
              Create.calculation(
                [
                  // Batterie Kapazität
                  // result = 84
                  Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 70),
                  Create.calculation(
                    // result = 1,2
                    [
                      Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 1),
                      Create.calculation(
                        // result = 0,2
                        [
                          // Ladeverlust
                          Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 20),
                          Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 100),
                        ],
                        CalcOperation.DIV,
                      ),
                    ],
                    CalcOperation.ADD,
                  ),
                ],
                CalcOperation.MUL,
                Create.valueLabel('Ladeenergie'),
              ),
              // Strompreis kWh
              Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 30),
            ],
            CalcOperation.MUL,
            Create.valueLabel('Ladekosten in Cent'),
          ),
          Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 100),
        ],
        CalcOperation.DIV,
        Create.valueLabel('Ladekosten in Euro'),
      ),
    },
    {
      label: 'Invalid Values',
      name: 'invalid-values',
      calculation: Create.calculation([
        Create.fieldRef('invalid-field'),
        Create.aliasValue('invalid-alias'),
        Create.baseDataValueRef('invalid-base-data'),
        Create.inputValue(DATA_VALUE_TYPE.STRING, 'invalid-value'),
        Create.calculation(),
      ]),
    },
  ];
  protected calulation: Calculation = this.calculations[0].calculation;

  constructor(private readonly cfgStateService: CfgStateService) {
    cfgStateService.insertConfiguration(mockConfiguration1() as CmsConfigurator);
  }
}

export function mockConfiguration1(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    versions: {
      current: 1,
    },
    name: 'simple-cfg1',
    label: 'Simple configuration 1',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          label: 'Page 1',
          name: 'page1',
          children: [
            {
              cls: CLS.FIELD_GROUP_WRAPPER,
              entry: {
                cls: CLS.FIELD_GROUP,
                label: 'Question 1',
                name: 'question1',
                children: [
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'textfield1',
                      label: 'Field 1',
                      config: Create.textFieldConfig({ required: true }),
                    },
                  },
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'datefield1',
                      label: 'Field 2',
                      config: Create.dateFieldConfig(),
                    },
                  },
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'numberfield1',
                      label: 'Field 3',
                      config: Create.numericFieldConfig(),
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  };
}
