<div class="page-toolbar">
  <div class="page-limiter page-padding-x flex-column">
    <p-toolbar styleClass="invisible no-padding-x">
      <div class="p-toolbar-group-left">
        <a routerLink="/" class="logo">
          <img src="assets/images/logo_full.svg" />
        </a>
      </div>
      <div class="p-toolbar-group-right"></div>
    </p-toolbar>
  </div>
</div>
<div class="flex flex-1 justify-content-center align-items-center scroll-area py-2">
  <ng-content></ng-content>
</div>
<div class="page-toolbar">
  <div class="page-limiter page-padding-x flex-column">
    <p-toolbar styleClass="invisible no-padding-x">
      <div class="p-toolbar-group-left text-xs">
        <a [href]="environment.apps.web">Web</a>
        <a [href]="environment.apps.web + '/content/imprint'" class="ml-2">Impressum</a>
        <a [href]="environment.apps.web + KFD_WEB_APP_ROUTES.legal.privacy" class="ml-2">Datenschutz</a>
        <a [href]="environment.apps.web + '/content/contact'" class="ml-2">Kontakt</a>
        <a [href]="environment.apps.web + '/support'" class="ml-2">Support</a>
      </div>
      <div class="p-toolbar-group-right"></div>
    </p-toolbar>
  </div>
</div>
