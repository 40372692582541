<div *ngIf="config" class="p-fluid p-2">
  <ng-content></ng-content>

  <div class="horizontal-line"></div>
  <div class="p-field">
    <label>Button Label</label>
    <input
      #btnLabelInput
      (change)="change('btnLabel', btnLabelInput.value)"
      [ngModel]="config.btnLabel"
      pInputText
      type="text"
    />
  </div>

  <div class="horizontal-line"></div>

  <div class="p-field">
    <label>Zielseite anzeigen</label>
    <kfd-page-ref
      (pageRefChange)="change('goToPage', $event)"
      [pageRef]="config.goToPage"
      emptyOptionLabel="- Kein Seitenwechsel -"
    ></kfd-page-ref>
  </div>

  <div class="p-field">
    <label>Nachricht anzeigen</label>
    <input
      #successTextInput
      (change)="change('successText', successTextInput.value)"
      [disabled]="!!config.goToPage"
      [ngModel]="config.successText"
      maxlength="300"
      pInputText
      type="text"
    />
  </div>
  <div class="p-field p-field-checkbox">
    <p-checkbox
      (onChange)="change('showRequestId', $event.checked)"
      [disabled]="!!config.goToPage"
      [ngModel]="config.showRequestId"
      binary="true"
      label="Request ID anzeigen"
    ></p-checkbox>
  </div>
  <div class="p-field p-field-checkbox">
    <p-checkbox
      (onChange)="change('linkRequestId', $event.checked)"
      [disabled]="!!config.goToPage || !config.showRequestId"
      [ngModel]="config.linkRequestId"
      binary="true"
      label="Request ID verlinken"
    ></p-checkbox>
  </div>
  <div class="p-field p-field-checkbox">
    <p-checkbox
      (onChange)="change('resetOnSubmit', $event.checked)"
      [ngModel]="config.resetOnSubmit"
      binary="true"
      inputId="resetOnSubmit"
      label="Eingaben zurücksetzen"
    ></p-checkbox>
  </div>

  <!--  <div class="p-field">-->
  <!--    <a [routerLink]="linkToRootSettings">-->
  <!--      <i class="icon pi pi-envelope"></i>-->
  <!--      Kunden Benachrichtigung einrichten-->
  <!--    </a>-->
  <!--  </div>-->
</div>
