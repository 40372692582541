import { Injectable } from '@angular/core';
import { ApiBaseDataTemplateService } from '../services/api/api-base-data-template.service';
import {
  BaseDataRef,
  BaseDataResolver,
  BaseDataTemplate,
  CmsSelectionData,
  Create,
  DATA_VALUE_TYPE,
  FieldRef,
  Is,
  SelectionData,
} from '@kfd/core';
import { CfgStateService } from './cfg-state.service';
import { CmsContextService } from '../services/cms-context.service';
import { BehaviorSubject, combineLatest, first } from 'rxjs';
import { BaseDataService } from '../services/base-data.service';

/**
 * Service to get data and template information for the current configuration
 */
@Injectable({ providedIn: 'root' })
export class CfgDataAndTemplateService implements BaseDataResolver {
  public isReady = new BehaviorSubject<boolean>(false);
  private templates: BaseDataTemplate[] | undefined;
  private baseDataEntries: CmsSelectionData[] | undefined;

  // private baseDataMap: BaseDataMap | undefined;

  constructor(
    private readonly ctx: CmsContextService,
    private readonly cfgStateService: CfgStateService,
    readonly baseDataService: BaseDataService,
    readonly apiBaseDataTemplateService: ApiBaseDataTemplateService,
  ) {
    const templates$ = this.apiBaseDataTemplateService.listBaseDataTemplates(this.ctx.projectId).pipe(first());
    const baseDataEntries$ = baseDataService.getData(this.ctx.projectId).pipe(first());

    combineLatest([templates$, baseDataEntries$]).subscribe(([templates, baseDataEntries]) => {
      this.templates = templates;
      this.baseDataEntries = baseDataEntries;
      // this.baseDataMap = new Map<string, SelectionData>();
      // this.baseDataEntries.map((entry) => {
      //   this.baseDataMap.set(entry.name, Create.selectionData(entry.name, entry.label, entry.values));
      // });
      this.isReady.next(true);
    });
  }

  /**
   * Get the data value type of a field reference
   * looking into base data template values for field references with properties
   */
  public getFieldRefDataValueType(fieldRef: FieldRef): DATA_VALUE_TYPE | undefined {
    const referenceField = this.cfgStateService.getCfgUtil().getFieldByName(fieldRef.name, true);
    if (Is.textField(referenceField)) {
      return DATA_VALUE_TYPE.STRING;
    }
    if (Is.numberField(referenceField)) {
      return DATA_VALUE_TYPE.NUMERIC;
    }
    if (Is.boolField(referenceField)) {
      return DATA_VALUE_TYPE.BOOL;
    }
    if (Is.dateField(referenceField)) {
      return DATA_VALUE_TYPE.DATE;
    }
    if (Is.displayDataField(referenceField)) {
      if (referenceField.config.dataType) {
        return referenceField.config.dataType;
      }
    }

    if (Is.selectField(referenceField)) {
      if (fieldRef.property && Is.dynamicDataHandler(referenceField.config.dataHandler)) {
        const template = this.getBaseDataTemplate(referenceField.config.dataHandler.templateName);
        if (!template) {
          return;
        }
        return template.values.find((value) => value.name === fieldRef.property)?.inputValue.type;
      } else if (referenceField.config.dataType) {
        return referenceField.config.dataType;
      }
    }
  }

  public getBaseDataTemplate(name: string): BaseDataTemplate | undefined {
    return this.templates.find((tpl) => tpl.name === name);
  }

  public getBaseDataEntries(): CmsSelectionData[] {
    return this.baseDataEntries ?? [];
  }

  // public getBaseDataMap(): BaseDataMap {
  //   return this.baseDataMap;
  // }

  resolve(baseDataRef: BaseDataRef): Promise<SelectionData | undefined> {
    const entry = this.baseDataEntries.find((entry) => entry.name === baseDataRef.name);
    if (!entry) {
      return Promise.resolve(undefined);
    }
    return Promise.resolve(Create.selectionData(entry.name, entry.label, entry.values));
  }
}
