<ng-containerg *ngIf="entry">
  <div class="p-fluid p-2">
    <kfd-hide-element-cfg-setting
      (valueChange)="change()"
      [(value)]="entry.hide"
      class="p-field"
    ></kfd-hide-element-cfg-setting>
    <kfd-hidelabel-cfg-setting (valueChange)="change()" [(value)]="entry.hideLabel" class="p-field">
    </kfd-hidelabel-cfg-setting>
  </div>
</ng-containerg>
