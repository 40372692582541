import { Component, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BaseDataRef,
  CfgUtil,
  CmsSelectionData,
  Configuration,
  DynamicDataHandler,
  EntryUsage,
  ICON,
  PUBLISHED_STATE,
} from '@kfd/core';
import { BaseDataService } from '../../../services/base-data.service';
import { CmsContextService } from '../../../services/cms-context.service';
import { Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { LoggingService } from '../../../services/logging.service';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { CfgStateService } from '../../cfg-state.service';
import { BasedataDynamicUsageDialogComponent } from '../../../project/basedata/basedata-dynamic-usage-dialog/basedata-dynamic-usage-dialog.component';
import { CmsDialogService } from '../../../services/cms-dialog.service';

@Component({
  selector: 'kfd-data-usage-dialog',
  templateUrl: './data-usage-dialog.component.html',
  styleUrls: ['./data-usage-dialog.component.scss'],
})
export class DataUsageDialogComponent extends BaseDialogComponent {
  protected readonly ICON = ICON;
  protected readonly states = PUBLISHED_STATE;

  protected cfgUtil: CfgUtil<Configuration>;
  protected loading = false;
  protected configuration: Configuration;
  protected entryUsages: EntryUsage<BaseDataRef>[] = [];
  protected dataRefEntries$: Observable<CmsSelectionData[]> | undefined;
  protected filterUsage: EntryUsage<DynamicDataHandler>[] = [];
  protected usageInfoMenuItems: MenuItem[] = [];
  @ViewChild('tableUsageInfoMenu')
  protected menu: ContextMenu | undefined;

  constructor(
    private readonly dataService: BaseDataService,
    private readonly cfgStateService: CfgStateService,
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    protected ctx: CmsContextService,
    protected cmsDialogService: CmsDialogService,
  ) {
    super(dialogRef, dialogConfig);
    this.configuration = dialogConfig.data.configuration;
    if (!this.configuration) {
      this.close();
      throw new Error('Missing configuration');
    }

    // this.cfgUtil = CfgUtil.create(this.configuration);
    this.cfgUtil = this.cfgStateService.getCfgUtil();
    this.initData();
  }

  initData() {
    this.entryUsages = this.cfgUtil.getAllBaseDataReferences();
    this.filterUsage = this.cfgUtil.filterUsage();

    this.dataRefEntries$ = this.dataService.getDataByRefList(
      this.ctx.projectId,
      this.entryUsages.map((dataRefNode) => dataRefNode.entry),
    );
  }

  showUsedBy(event: MouseEvent, name: string) {
    const usages = this.entryUsages.filter((value) => value.entry.name === name);
    this.usageInfoMenuItems = this.getUsageInfoMenu(usages);
    if (this.menu) {
      this.menu.show(event);
    }
    event.stopPropagation();
  }

  showDynamicUsage(templateName: string, tags: string[]) {
    this.cmsDialogService.open(BasedataDynamicUsageDialogComponent, {
      showAddDataBtn: false,
      templateName,
      tags,
    });
  }

  private getUsageInfoMenu(usages: EntryUsage<unknown>[]): MenuItem[] {
    const usageInfoMenuItems = [];
    if (usages.length === 0) {
      usageInfoMenuItems.push({
        label: '- Keine Nutzung gefunden -',
        icon: 'pi ' + ICON.WARN,
      });
      return usageInfoMenuItems;
    }
    for (const usage of usages) {
      usageInfoMenuItems.push({
        label: usage.usedBy.label,
        icon: 'pi  ' + ICON.LINK_EXTERNAL,
        command: () => {
          const entry = this.cfgUtil.getEntryByName(usage.usedBy.name);
          this.close(entry);
        },
      });
    }

    return usageInfoMenuItems;
  }
}
