<div *ngIf="config" class="p-fluid p-2">
  <ng-content></ng-content>

  <kfd-required-cfg-setting (valueChange)="change('required', $event)" [value]="config.required" class="mt-2">
  </kfd-required-cfg-setting>
  <div class="horizontal-line"></div>

  <div class="p-field">
    <kfd-toggle-setting
      (disable)="change('default', undefined)"
      (enable)="changeValue('default', defaultValue)"
      [object]="config"
      [property]="'default'"
      class="p-field"
      data-automationId="bool-config-default"
      label="Standardwert verwenden"
      pTooltip="Setzt einen Wert als Vorauswahl. Somit ist immer ein Wert ausgewählt."
    >
      <ng-container *ngIf="config.default">
        <p-selectButton
          (onChange)="change('default.value', $event.value)"
          [ngModel]="config.default.value"
          [options]="boolValues"
          id="configDefault"
        >
        </p-selectButton>
      </ng-container>
    </kfd-toggle-setting>
  </div>
  <div class="p-field">
    <kfd-toggle-setting
      (disable)="change('labels', undefined)"
      (enable)="change('labels', defaultLabels)"
      [object]="config"
      [property]="'labels'"
      class="p-field"
      data-automationId="bool-config-labels"
      label="Labels ändern"
      pTooltip="Die Auswahlfelder können über eigene Labels die Bedeutung eindeutiger anzeigen."
    >
      <div *ngIf="config.labels" class="p-fluid">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-check-circle"></i></span>
          <input
            #labelYes
            (change)="change('labels.yes', labelYes.value)"
            [ngModel]="config.labels.yes"
            data-automationId="bool-config-label-yes-input"
            maxlength="20"
            pInputText
            placeholder="Ja"
            type="text"
          />
        </div>
        <div class="p-inputgroup mt-1">
          <span class="p-inputgroup-addon"><i class="pi pi-times-circle"></i></span>
          <input
            #labelNo
            (change)="change('labels.no', labelNo.value)"
            [ngModel]="config.labels.no"
            data-automationId="bool-config-label-no-input"
            maxlength="20"
            pInputText
            placeholder="Nein"
            type="text"
          />
        </div>
      </div>
    </kfd-toggle-setting>
  </div>
</div>
