@if (viewData$ | async; as viewData) {
  <kfd-preview-dropzone
    [accepts]="allowedChildren[CLS.CONFIGURATION]"
    [parent]="viewData.cfgSkeleton.name"
    [vertical]="true"
  >
  </kfd-preview-dropzone>
  @for (page of viewData.cfgSkeleton.children; track page.name; let cfgPosition = $index) {
    <!-- the kfd-container class is required to apply custom ci styling -->
    <div class="kfd-container">
      <kfd-dnd-area-page
        [attr.data-entry-name]="page.name"
        [pageName]="page.name"
        [pageWidth]="pageWidth"
        [pageHeight]="pageHeight"
        [style.flex]="'0 ' + pageHeight + 'px ' + pageWidth + 'px'"
      >
      </kfd-dnd-area-page>
    </div>
    <kfd-preview-dropzone
      [accepts]="allowedChildren[CLS.CONFIGURATION]"
      [parent]="viewData.cfgSkeleton.name"
      [position]="cfgPosition + 1"
      [vertical]="true"
      [area]="cfgPosition === viewData.cfgSkeleton.children.length - 1"
    ></kfd-preview-dropzone>
  }
  @if (viewData.editMode) {
    <div class="flex-1 justify-content-center editor-element flex flex-column"
    >
      <p-button
        icon="pi {{ ICON.ADD }}"
        pTooltip="Neue Seite anlegen"
        data-automationId="add-new-page-quick-btn"
        [rounded]="true"
        [outlined]="true"
        (click)="createNewPage()"
      ></p-button>
    </div>
  }
}
