import { Component } from '@angular/core';
import { BaseDialogComponent } from '../../../../common/base-dialog.component';
import {
  ArrayUtil,
  BaseDataRef,
  BaseDataTemplate,
  CmsSelectionData,
  Create,
  DATA_VALUE_TYPE,
  DATAHANDLER_TYPES,
  DataHandlers,
  DynamicDataHandler,
  ICON,
  Is,
  ObjectUtil,
  SelectionData,
  SelectOption,
  SimpleListHandler,
} from '@kfd/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseDataManagementService } from '../../../../project/basedata/base-data-management.service';
import { BaseDataService } from '../../../../services/base-data.service';
import { CmsContextService } from '../../../../services/cms-context.service';
import { DATAHANDLER_TYPE_OPTION_LIST } from '../../../../shared/global';
import { ConfigurationDataService } from '../../../../services/configuration-data.service';
import { Observable, of, tap } from 'rxjs';

@Component({
  selector: 'kfd-data-handler-dialog',
  templateUrl: './basedata-handler-dialog.component.html',
  styleUrls: ['./basedata-handler-dialog.component.scss'],
})
export class BasedataHandlerDialogComponent extends BaseDialogComponent {
  protected readonly ICON = ICON;
  protected readonly expectedDataType = DATA_VALUE_TYPE.STRING;
  protected readonly dataHandlerTypes = DATAHANDLER_TYPES;
  protected readonly dataOptions = DATAHANDLER_TYPE_OPTION_LIST;
  protected sortOptions$: Observable<SelectOption[]> | undefined;
  protected selectedData: CmsSelectionData[] = [];
  protected dynamicHandlerData$: Observable<SelectionData[]>;
  protected dataHandler: SimpleListHandler | DynamicDataHandler | undefined;
  protected error: 'dynamic-one-filter-required' | undefined;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected baseDataManagementService: BaseDataManagementService,
    protected dataService: BaseDataService,
    protected configurationDataService: ConfigurationDataService,
    public ctx: CmsContextService,
  ) {
    super(dialogRef, dialogConfig);
    this.setDataHandler(
      this.dialogConfig.data?.dataHandler?.type,
      this.dialogConfig.data?.dataHandler ? ObjectUtil.clone(this.dialogConfig.data.dataHandler) : undefined,
    );
  }

  public selectedDataTrackBy(index: number, cmsSelectionData: CmsSelectionData) {
    return cmsSelectionData._id;
  }

  public addData() {
    const selectedIds = this.selectedData ? ArrayUtil.filterUndefined(this.selectedData.map((d) => d._id)) : [];

    this.baseDataManagementService
      .selectData(this.ctx.projectId, this.ctx.configuratorId, selectedIds)
      .subscribe((selectionData) => {
        if (Array.isArray(selectionData)) {
          this.selectedData = selectionData;
          this.selectedDataToConfig();
        }
      });
  }

  public removeDataEntry(name: string) {
    this.selectedData = this.selectedData.filter((entry) => entry.name !== name);
    this.selectedDataToConfig();
  }

  public selectedDataToConfig() {
    if (!this.dataHandler) {
      return;
    }
    const data = this.selectedData.map((d) => Create.baseDataRef(d.name));
    (this.dataHandler as SimpleListHandler).data = data;
  }

  public onTagSelectionChange(tags: string[]) {
    if (!this.dataHandler || !Is.dynamicDataHandler(this.dataHandler)) {
      return;
    }
    this.dataHandler.tags = tags;
    this.error = undefined;
    this.loadDynamicData();
  }

  public onTemplateSelectionChange(template: BaseDataTemplate) {
    if (!this.dataHandler || !Is.dynamicDataHandler(this.dataHandler)) {
      return;
    }
    this.dataHandler.templateName = template.name;
    this.error = undefined;
    this.loadDynamicData();
  }

  public dataEntryCreated(selectionData: CmsSelectionData): void {
    this.selectedData = [...this.selectedData, selectionData];
    this.selectedDataToConfig();
  }

  public acceptDataHandler() {
    if (!Is.dynamicDataHandler(this.dataHandler) && !Is.listDataHandler(this.dataHandler)) {
      return;
    }

    if (Is.dynamicDataHandler(this.dataHandler)) {
      if (!this.dataHandler.templateName && (!this.dataHandler.tags || this.dataHandler.tags.length === 0)) {
        this.error = 'dynamic-one-filter-required';
        return;
      }
    }
    this.close(this.dataHandler);
  }

  protected setDataHandler(type = DATAHANDLER_TYPES.DYNAMIC, defaultValues?: DataHandlers) {
    if (this.dataHandler && this.dataHandler.type === type) {
      return;
    }
    if (type === DATAHANDLER_TYPES.DYNAMIC) {
      if (Is.dynamicDataHandler(defaultValues)) {
        this.dataHandler = Create.dynamicDataHandler(
          defaultValues.templateName,
          defaultValues.tags,
          defaultValues.sort,
        );
      } else {
        this.dataHandler = Create.dynamicDataHandler();
      }
      this.loadDynamicData();
    } else {
      this.dataHandler = Create.simpleListDataHandler();
      if (Is.listDataHandler(defaultValues)) {
        this.dataService
          .getDataByRefList(this.ctx.projectId, defaultValues.data.filter((d) => Is.baseDataRef(d)) as BaseDataRef[])
          .subscribe((data) => {
            this.selectedData = data;
          });
      }
    }
  }

  private loadDynamicData(): void {
    if (!Is.dynamicDataHandler(this.dataHandler)) {
      this.dynamicHandlerData$ = undefined;
      return;
    }
    if (!this.dataHandler.templateName && (!this.dataHandler.tags || this.dataHandler.tags.length === 0)) {
      this.dynamicHandlerData$ = undefined;
      return;
    }
    this.dynamicHandlerData$ = this.configurationDataService
      .getData(this.ctx.projectId, this.ctx.configuratorId, {
        templateName: this.dataHandler.templateName,
        tags: this.dataHandler.tags,
      })
      .pipe(
        tap((data) => {
          const list = data
            .map((selectionData) => {
              if (!selectionData.values) {
                return [];
              }
              return selectionData.values.map((value) => ({
                label: `Wert: ${value.label.text}`,
                value: value.identifier,
              }));
            })
            .flat();
          this.loadSortOptions(list);
        }),
      );
  }

  private loadSortOptions(options: SelectOption[]): void {
    this.sortOptions$ = of(
      ArrayUtil.uniqueByKey(
        [
          {
            label: 'Datensatz Label',
            value: 'label',
          },
          {
            label: 'Datensatz Name',
            value: 'name',
          },
          ...options,
        ],
        'value',
      ),
    );
  }
}
