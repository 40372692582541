<div class="p-field p-field-checkbox">
  <p-checkbox
    (onChange)="valueChange.emit($event.checked)"
    [ngModel]="value"
    binary="true"
    data-automationId="hide-label-checkbox"
    label="Label ausblenden"
    pTooltip="Blendet die Feldbezeichnung aus. Zum Beispiel wenn das Feld durch den Kontext selbsterklärend ist"
    tooltipPosition="left"
  ></p-checkbox>
</div>
