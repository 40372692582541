import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  CalcOperation,
  Calculation,
  CalculationDetails,
  CalculationFunction,
  CalculationHandler,
  CalculationParam,
  CalculationParamDetails,
  CalculationResolver,
  CLS,
  Create,
  DATA_VALUE_TYPE,
  FIELD_TYPES,
  ICON,
  Is,
  SelectOption,
  unvTimeout,
  ValueLabel,
} from '@kfd/core';
import { validate } from 'uuid';
import {
  calcOperationIcons,
  calcOperationList,
  calculationOperationLabels,
  calculationOperationResults,
} from '../../../shared/global';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CfgDataAndTemplateService } from '../../cfg-data-and-template.service';
import { CfgEditorFieldRefResolver } from '../../cfg-editor-field-ref-resolver';
import { CfgEditorCalculationRefResolver } from '../../cfg-editor-calculation-ref-resolver';

@Component({
  selector: 'kfd-calculation-editor',
  templateUrl: './calculation-editor.component.html',
  styleUrl: './calculation-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationEditorComponent {
  @Input()
  public rootCalculationHandler: CalculationHandler | undefined;
  @Input()
  public editMode = true;
  @Output()
  public calculationChange = new EventEmitter<Calculation>();
  @Output()
  public isValid = new EventEmitter<boolean>();
  @Input()
  @HostBinding('class.edit-mode')
  public allowEdit = true;
  protected readonly calcOperationIcons = calcOperationIcons;
  protected readonly FIELD_TYPES = FIELD_TYPES;
  protected readonly calculationOperationResults = calculationOperationResults;
  protected readonly calculationOperationLabels = calculationOperationLabels;
  protected readonly CLS = CLS;
  protected readonly ICON = ICON;
  protected readonly allowedDataValueTypes = [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE];
  protected readonly calcOperationList = calcOperationList;
  protected readonly DATA_VALUE_TYPE = DATA_VALUE_TYPE;
  protected selectedParam: CalculationParamDetails | undefined;
  protected paramOptionList: SelectOption[] = [];
  protected calculationHandler: CalculationHandler | undefined;
  @ViewChild('addParamOverlayPanel')
  protected addParamOverlayPanel: OverlayPanel;
  @ViewChild('editParamPanel')
  protected editParamPanel: OverlayPanel;
  @ViewChild('editCalculationOverlay')
  protected editCalculationOverlay: OverlayPanel;
  private readonly calculationResolver: CalculationResolver;

  constructor(
    readonly cfgDataAndTemplateService: CfgDataAndTemplateService,
    readonly cfgEditorFieldRefResolver: CfgEditorFieldRefResolver,
    readonly cfgEditorCalculationRefResolver: CfgEditorCalculationRefResolver,
  ) {
    this.calculationResolver = new CalculationResolver(
      cfgEditorFieldRefResolver,
      cfgDataAndTemplateService,
      cfgEditorCalculationRefResolver,
    );
    this.updateParamOptionList();
  }

  private _level = 0;

  public get level(): number {
    return this._level;
  }

  @Input()
  public set level(value: number | string) {
    const level = typeof value === 'string' ? parseInt(value) : value;
    if (this.level !== level) {
      this._level = level;
      this.calculation = this._calculation;
    }
    // this.editMode = this.level === 0;
    this.editMode = true;
    if (this.level === 0 && this.calculationHandler) {
      this.rootCalculationHandler = this.calculationHandler;
    }
  }

  private _calculation: CalculationDetails = Create.calculationDetails();

  public get calculation(): CalculationDetails {
    return this._calculation;
  }

  @Input({ required: true })
  public set calculation(calculation: Calculation | CalculationDetails) {
    if (!Is.calculation(calculation)) {
      return;
    }

    // this._calculation = ObjectUtil.clone({
    //   ...calculation,
    //   params: calculation.params.map((param, index) => this.createEditorParam(param, index)),
    // });
    // console.log(this._calculation, calculation, ObjectUtil.equals(this._calculation, calculation));
    // if(ObjectUtil.equals(this._calculation, calculation))
    this._calculation = Create.calculationDetails();
    this.calculationResolver.validate(calculation).then((calculation) => {
      this._calculation = calculation;

      this.isValid.emit(calculation.valid);

      this.calculationHandler = new CalculationHandler(this._calculation);
      if (this.level === 0) {
        this.rootCalculationHandler = this.calculationHandler;
      }
    });

    // this.changeDetectorRef.detectChanges();
  }

  @ViewChildren('paramElement')
  protected set paramElementList(list: QueryList<ElementRef>) {
    // console.log(this.selectedParam);
    if (!this.selectedParam) {
      return;
    }
    if (Is.calculation(this.selectedParam)) {
      return;
    }
    //find the element with the id of the selected param
    const element = list.find((element) => element.nativeElement.id === this.selectedParam.id);
    if (element) {
      unvTimeout(() => {
        //show edit overlay for the selected element
        this.editParamPanel.show({
          target: element.nativeElement,
        });
      }, 100);
    }
  }

  protected cancelEdit(): void {
    this.editMode = false;
    this.editCalculationOverlay.hide();
    this.editParamPanel.hide();
    this.addParamOverlayPanel.hide();
  }

  protected selectParam(event: MouseEvent, param: CalculationParamDetails): void {
    this.selectedParam = param;
    this.editParamPanel.show(event);
    this.editCalculationOverlay.hide();
  }

  protected acceptParam(paramId: string, updatedParam: CalculationParam): void {
    this.calculation.params = this.calculation.params.map((param: CalculationParamDetails, index) => {
      if (param.id === paramId) {
        return this.createEditorParam(updatedParam, index);
      }
      return param;
    });
    this.selectedParam = undefined;
    this.emitCalculationChange();
    this.editParamPanel.hide();
  }

  protected acceptCalculationSettings(settings: {
    label?: ValueLabel;
    alias?: string;
    excludeFromResult?: boolean;
    operation?: CalcOperation;
    fn?: CalculationFunction;
  }): void {
    if (settings.label && settings.label.text.length > 0) {
      this.calculation.label = settings.label;
    }
    if (settings.alias) {
      this.calculation.alias = settings.alias;
    }
    if (settings.excludeFromResult !== undefined) {
      this.calculation.excludeFromResult = settings.excludeFromResult;
    }
    if (settings.operation) {
      this.calculation.op = settings.operation;
    }
    if (settings.fn) {
      this.calculation.fn = settings.fn;
    }

    this.selectedParam = undefined;
    this.emitCalculationChange();
    this.editCalculationOverlay.hide();
  }

  protected addParam(type: string | undefined): void {
    let newParam: CalculationParam | undefined;
    switch (type) {
      case CLS.INPUT_VALUE: {
        newParam = Create.createDefaultInputValue(DATA_VALUE_TYPE.NUMERIC);
        break;
      }
      case CLS.ALIAS_VALUE: {
        newParam = Create.aliasValue();
        break;
      }
      case CLS.FIELD_REF: {
        newParam = Create.fieldRef();
        break;
      }
      case CLS.CALCULATION: {
        newParam = Create.calculation();
        break;
      }
      case CLS.BASE_DATA_REF: {
        newParam = Create.baseDataValueRef();
        break;
      }
      case CLS.CALCULATION_REF: {
        newParam = Create.calculationRef();
        break;
      }
      default: {
        throw new Error('Unknown param type ' + type);
      }
    }
    if (newParam) {
      const param = this.createEditorParam(newParam, this.calculation.params.length);
      this.calculation.params.push(param);
      this.selectedParam = param;
      // we do not emit calculation changes here to prevent from refreshing and emitting empty params
      // if the param won't be updated the changes won't be emitted by purpose
    }
    this.addParamOverlayPanel.hide();
  }

  protected deleteParam(paramToDelete: CalculationParamDetails): void {
    this.calculation.params = this.calculation.params.filter((param) => param.id !== paramToDelete.id);
    this.selectedParam = undefined;
    this.emitCalculationChange();
  }

  protected getAliasForEdit(): string {
    return validate(this.calculation.alias) ? '' : this.calculation.alias;
  }

  private updateParamOptionList() {
    const paramOptionList = [
      {
        icon: ICON.ENTRY,
        value: CLS.FIELD_REF,
        label: 'Feld',
        description: 'Feld-Wert aus einer Benutzereingabe',
      },
      {
        icon: ICON.BASE_DATA,
        value: CLS.BASE_DATA_REF,
        label: 'Datensatz',
        description: 'Wert aus einem Stammdatensatz',
      },
      {
        icon: ICON.ALIAS,
        value: CLS.ALIAS_VALUE,
        label: 'Zwischenergebnis',
        description: 'Wert aus einer Zwischenberechnung',
      },
      {
        icon: ICON.CALCULATION_REF,
        value: CLS.CALCULATION_REF,
        label: 'Externe Berechnung',
        description: 'Wert aus einer anderen Berechnung',
      },
      {
        icon: ICON.ENTRY_NUMBER,
        value: CLS.INPUT_VALUE,
        label: 'Wert',
        description: 'Manuelle eingetragener Wert',
      },
    ];
    if (this._level < 3) {
      this.paramOptionList = [
        {
          icon: ICON.CALCULATION,
          value: CLS.CALCULATION,
          label: 'Kalkulation',
          description: 'Unterberechnung für weitere Operationen',
        },
        ...paramOptionList,
      ];
    } else {
      this.paramOptionList = [...paramOptionList];
    }
  }

  private createEditorParam(param: CalculationParam, index: number): CalculationParamDetails {
    return {
      ...param,
      id: `param-${this.level}-${index}`,
    };
  }

  private emitCalculationChange(): void {
    this.calculationChange.emit(CalculationHandler.removeDetails(this.calculation));
  }
}
