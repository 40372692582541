import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalculationRef, Create, ICON } from '@kfd/core';
import { CfgStateService } from '../../cfg-state.service';

@Component({
  selector: 'kfd-calculation-ref',
  templateUrl: './calculation-ref.component.html',
  styleUrl: './calculation-ref.component.scss',
})
export class CalculationRefComponent {
  private _calculationRef: CalculationRef | undefined;

  protected readonly ICON = ICON;
  protected selection: string | undefined;

  @Output()
  public calculationRefChange = new EventEmitter<CalculationRef>();

  constructor(protected readonly cfgStateService: CfgStateService) {}

  public get calculationRef(): CalculationRef | undefined {
    return this._calculationRef;
  }

  @Input()
  public set calculationRef(value: CalculationRef | undefined) {
    this._calculationRef = value;
    const entry = this.cfgStateService
      .getCfgUtil()
      .getCalculations()
      .find((entry) => entry.name === value?.name);
    if (entry) {
      this.selection = entry.name;
    }
  }

  protected select() {
    if (!this.selection) {
      return;
    }
    this.calculationRefChange.emit(Create.calculationRef(this.selection));
  }
}
