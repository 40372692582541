@if (viewData$ | async; as viewData) {
  <div
    [attr.data-automationId]="'cfg-element-' + viewData.group.entry.name"
    [ngClass]="{ selected: viewData.selected }"
    class="wrapper"
  >
    @if (viewData.group.isNew) {
      <kfd-placeholder-msg
        (tap)="select()"
        [infoIcon]="ICON.INFO"
        [interactOptions]="{ eventPropagation: false }"
        infoText="Die Gruppe wurde noch nicht gespeichert"
        interactPointer
      ></kfd-placeholder-msg>
    } @else {
      @if (viewData.editMode && viewData.selected) {
        <div class="toolbar">
          <i class="pi drag-initiator {{ ICON.MOVE }}" #dragInitiator></i>
        </div>
      }
      <div class="info-area">
        @if (viewData.group.entry.hide) {
          <i
            class="pi {{ ICON.INVISIBLE }}"
            data-automationId="element-hidden-indicator"
            pTooltip="Das Element ist nicht sichtbar"
          ></i>
        } @else {
          <kfd-condition-viewer [conditionalEntry]="viewData.group"></kfd-condition-viewer>
        }
      </div>
      <div class="content" (tap)="select()" [interactOptions]="{ eventPropagation: false }" interactPointer>
        <div
          [ngClass]="{ 'hide-label': viewData.group.entry.hideLabel }"
          class="element-label"
          data-automationId="element-selector"
          (tap)="select()"
          [interactOptions]="{ eventPropagation: false }"
          interactPointer
        >
          <i class="pi {{ ICON.GROUP }}"></i>
          <span class="ml-1 label">{{ viewData.group.entry.label }}</span>
        </div>
        @if (viewData.groupSkeleton.children.length === 0) {
          <kfd-preview-dropzone
            [accepts]="allowedChildren[CLS.FIELD_GROUP]"
            [area]="true"
            [parent]="viewData.group.entry.name"
          >
            <kfd-placeholder-msg
              (btnClick)="select(); cfgEditorService.createNewElement(CLS.PAGE)"
              [btnVisible]="true"
              [infoIcon]="ICON.GROUP"
              [interactOptions]="{ eventPropagation: false }"
              class="flex-1 justify-content-center editor-element"
              data-automationId="empty-group-info-msg"
              infoText="Kein Feld vorhanden"
              interactPointer
            ></kfd-placeholder-msg>
          </kfd-preview-dropzone>
        } @else {
          <kfd-preview-dropzone
            [accepts]="allowedChildren[CLS.FIELD_GROUP]"
            [parent]="viewData.group.entry.name"
          ></kfd-preview-dropzone>
          @for (groupItem of viewData.groupSkeleton.children; track groupItem.name; let groupPosition = $index) {
            <div class="page-element element-wrapper">
              <kfd-dnd-area-field [attr.data-entry-name]="groupItem.name" [fieldName]="groupItem.name">
              </kfd-dnd-area-field>
            </div>
            <kfd-preview-dropzone
              [accepts]="allowedChildren[CLS.FIELD_GROUP]"
              [parent]="viewData.group.entry.name"
              [position]="groupPosition + 1"
              [area]="groupPosition === viewData.groupSkeleton.children.length - 1"
            ></kfd-preview-dropzone>
          }
        }
      </div>
    }
  </div>
}
