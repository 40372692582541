<div class="p-2">
  @if (entry) {
    <kfd-hide-element-cfg-setting
      (valueChange)="change()"
      [(value)]="entry.hide"
      class="p-field"
    ></kfd-hide-element-cfg-setting>
    <kfd-hidelabel-cfg-setting (valueChange)="change()" [(value)]="entry.hideLabel" class="p-field">
    </kfd-hidelabel-cfg-setting>
    <div class="p-field p-field-checkbox">
      <p-checkbox
        (onChange)="change()"
        [(ngModel)]="entry.noNavPrev"
        [disabled]="!!isFirst"
        binary="true"
        label="Zurück deaktivieren"
        pTooltip="Versteckt den Zurück-Button und verhindert damit ein manuelles Zurück-Navigieren."
      ></p-checkbox>
    </div>
    <div class="p-field p-field-checkbox">
      <p-checkbox
        (onChange)="change()"
        [(ngModel)]="entry.noNavNext"
        [disabled]="!!isLast"
        binary="true"
        label="Weiter deaktivieren"
        pTooltip="Versteckt den Weiter-Button und verhindert damit ein manuelles Weiter-Navigieren."
      ></p-checkbox>
    </div>
  }
</div>
