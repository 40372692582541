import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Create,
  DATA_VALUE_TYPE,
  FIELD_TYPES,
  FieldRef,
  ICON,
  INPUT_FIELD_TYPES,
  Is,
  ObjectUtil,
  SelectOption,
} from '@kfd/core';
import { CfgStateService } from '../../cfg-state.service';
import { ApiBaseDataTemplateService } from '../../../services/api/api-base-data-template.service';
import { CmsContextService } from '../../../services/cms-context.service';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { entryIcon } from '../../../shared/global';

@Component({
  selector: 'kfd-field-ref',
  templateUrl: './field-ref.component.html',
  styleUrls: ['./field-ref.component.scss'],
})
export class FieldRefComponent implements OnInit {
  @Output()
  public fieldRefChange: EventEmitter<FieldRef> = new EventEmitter<FieldRef>();

  @Input()
  public fieldTypes: FIELD_TYPES[] = INPUT_FIELD_TYPES;

  @Input()
  public emptyMsg = '';

  @Input()
  public filterProperty = '';

  @Input()
  public filterValue = '';

  @Input()
  public allowDefaultValue = false;

  @Input()
  public inline = false;

  // shows accept button and disable automatic updates
  @Input()
  public showAcceptBtn = false;

  protected readonly ICON = ICON;
  protected fieldSelectOptions: SelectOption[] = [];
  protected propertiesSelectOptions$: Observable<SelectOption[]> = of([]);
  // used to determine the data type for an (optional) default value based on the selected field (property)
  protected defaultValueDataType: DATA_VALUE_TYPE | undefined;

  constructor(
    private cfgStateService: CfgStateService,
    private ctx: CmsContextService,
    private apiBaseDataTemplateService: ApiBaseDataTemplateService,
  ) {}

  private _allowProperties = false;

  public get allowProperties(): boolean {
    return this._allowProperties;
  }

  @Input()
  public set allowProperties(value: boolean) {
    this._allowProperties = value;
  }

  private _fieldRef: FieldRef = Create.fieldRef();

  public get fieldRef(): FieldRef {
    return this._fieldRef;
  }

  @Input()
  public set fieldRef(value: FieldRef) {
    if (!Is.fieldRef(value)) {
      this._fieldRef = Create.fieldRef();
      this._originFieldRef = this._fieldRef;
      return;
    }
    this._fieldRef = value;
    this._originFieldRef = this._fieldRef;
    this.loadProperties();
  }

  private _originFieldRef: FieldRef = Create.fieldRef();

  public get originFieldRef(): FieldRef {
    return this._originFieldRef;
  }

  public ngOnInit() {
    this.loadFields();
  }

  loadFields() {
    const cfgUtil = this.cfgStateService.getCfgUtil();
    const fields = cfgUtil.getFieldsOfType(this.fieldTypes).filter((field) => {
      if (!this.filterProperty) {
        return true;
      }
      return ObjectUtil.getValue(field, this.filterProperty) === this.filterValue;
    });
    if (fields.length === 0) {
      return;
    }
    const fieldOptions: SelectOption[] = fields.map((field) => {
      return {
        value: field.name,
        label: field.label ? field.label : field.name,
        description: field.config.type,
        icon: entryIcon(field),
      };
    });

    this.fieldSelectOptions = [
      {
        value: '',
        label: '- Feld wählen -',
      },
      ...fieldOptions,
    ];
  }

  protected onSelectField(event: { value: string }) {
    this._fieldRef = Create.fieldRef(event.value);
    this.loadProperties();
    if (this.inline) {
      this.onChange();
    }
  }

  protected onSelectProperty(event: { value: string }) {
    if (!Is.fieldRef(this._fieldRef) || !this._fieldRef.name) {
      return;
    }
    this._fieldRef.property = event.value;
    this.loadProperties();
    if (this.inline) {
      this.onChange();
    }
  }

  protected loadProperties() {
    if (!Is.fieldRef(this._fieldRef) || !this._fieldRef.name) {
      this.propertiesSelectOptions$ = of([]);
      return;
    }
    const cfgUtil = this.cfgStateService.getCfgUtil();
    const field = cfgUtil.getFieldByName(this._fieldRef.name);
    if (
      !field ||
      !Is.selectField(field) ||
      !Is.dynamicDataHandler(field.config.dataHandler) ||
      !field.config.dataHandler.templateName
    ) {
      this.propertiesSelectOptions$ = of([]);
      this.defaultValueDataType = undefined;
      return;
    }
    this.defaultValueDataType = field.config.dataType;
    this.propertiesSelectOptions$ = this.apiBaseDataTemplateService
      .getBaseDataTemplate(this.ctx.projectId, field.config.dataHandler.templateName)
      .pipe(
        tap((value) => {
          if (this._fieldRef.property) {
            this.defaultValueDataType = value.values.find((v) => v.name === this._fieldRef.property)?.inputValue.type;
          }
        }),
        map((tpl) => {
          if (!tpl) {
            return [];
          }
          return tpl.values.map((value) => {
            return {
              value: value.name,
              label: value.label,
            };
          });
        }),
      );
  }

  protected reset(): void {
    this.fieldRef = this.originFieldRef;
  }

  protected onChange() {
    if (!Is.fieldRef(this._fieldRef) || !this._fieldRef.name) {
      return;
    }
    if (!this.showAcceptBtn) {
      this.save();
    }
  }

  protected save() {
    if (!Is.fieldRef(this._fieldRef) || !this._fieldRef.name) {
      return;
    }
    this._originFieldRef = this._fieldRef;
    this.fieldRefChange.emit(this._fieldRef);
  }
}
