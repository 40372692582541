import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'kfd-hide-element-cfg-setting',
  standalone: true,
  imports: [CommonModule, CheckboxModule, TooltipModule, FormsModule],
  templateUrl: './hide-element-cfg-setting.component.html',
  styleUrl: './hide-element-cfg-setting.component.scss',
})
export class HideElementCfgSettingComponent {
  @Output()
  public valueChange = new EventEmitter<boolean>();

  private _value = false;

  public get value(): boolean {
    return this._value;
  }

  @Input()
  public set value(value: boolean) {
    this._value = value;
  }
}
