<div class="flex">
  @if (aliase.length > 0) {
    <p-dropdown class="flex-1" (ngModelChange)="nameChange($event)" [ngModel]="aliasValue.name" [options]="aliase">
    </p-dropdown>
    @if (showAcceptBtn) {
      <p-button icon="pi {{ ICON.ACCEPT }}" [outlined]="true" (click)="save()"></p-button>
    }
  } @else {
    <i>Keine Auswahl-Optionen verfügbar</i>
  }
</div>
