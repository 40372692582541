import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Type } from '../../directives/valid-name.directive';

/**
 * @deprecated use custom validator and toggle-input-field instead
 */
@Component({
  selector: 'kfd-unique-name-check',
  templateUrl: './unique-name-check.component.html',
  styleUrls: ['./unique-name-check.component.scss'],
})
export class UniqueNameCheckComponent {
  @Output()
  public isUnique: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  public type: Type = Type.ENTRY_NAME;
  @Input()
  public minLength = 3;
  protected checkedName = '';
  private loading = false;
  private unique = false;

  private _name = '';

  get name(): string {
    return this._name;
  }

  @Input()
  set name(value: string) {
    this._name = value;
    this.checkedName = '';
  }

  public uniqueChange(value: boolean) {
    this.unique = value;
    this.isUnique.emit(value);
    this.checkedName = this.name;
  }
}
