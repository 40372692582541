import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CheckResult, CLS, CmsGenericEntry, ConfigurationChecker, Create } from '@kfd/core';
import { CfgStateService } from './cfg-state.service';
import { NewElementInfo } from './new-entry/new-entry.component';
import { CfgDataAndTemplateService } from './cfg-data-and-template.service';
import { CalculationManagerComponent } from './dialogs/calculation-manager/calculation-manager.component';
import { CmsDialogService } from '../services/cms-dialog.service';

/**
 * handles general logic for the editor
 */
@Injectable({
  providedIn: 'root',
})
export class CfgEditorService {
  public onCreateNewElement = new Subject<NewElementInfo | undefined>();
  public checkResults = new BehaviorSubject<CheckResult[]>([]);
  public readonly onSelectionChange = new BehaviorSubject<CmsGenericEntry | undefined>(undefined);
  public readonly onEditModeChange = new BehaviorSubject<boolean>(false);

  private selectedEntry: string | undefined;

  constructor(
    private readonly cfgStateService: CfgStateService,
    private readonly cfgDataAndTemplateService: CfgDataAndTemplateService,
    private readonly cmsDialogService: CmsDialogService,
  ) {
    cfgStateService.onConfigurationChange.subscribe((configuration) => {
      this.updateCheckResults();
      if (configuration) {
        if (!this.selectEntry(this.selectedEntry, true)) {
          // select first page if exists in new configuration
          const firstPage = this.cfgStateService.getCfgUtil().getFirstPage();
          if (firstPage) {
            this.selectEntry(firstPage.name);
          } else {
            this.onSelectionChange.next(undefined);
          }
        }
      }
    });
    cfgStateService.onStructureChange.subscribe(() => this.updateCheckResults());
    cfgStateService.onEntryChange().subscribe((entry) => {
      this.updateCheckResults();
      if (entry && this.selectedEntry === entry.name) {
        this.selectEntry(entry.name, true);
      }
    });
    cfgStateService.onNewEntry.subscribe((entryName) => {
      this.selectEntry(entryName);
    });
    cfgStateService.onEntryDeletion.subscribe(() => {
      this.selectEntry(undefined);
    });
  }

  private _editMode = false;

  public get editMode(): boolean {
    return this._editMode;
  }

  public set editMode(value: boolean) {
    // todo implement locking in backend
    if (this._editMode === value) {
      return;
    }
    this._editMode = value;
    this.cfgStateService.allowChanges = value;
    this.onEditModeChange.next(this._editMode);
  }

  public get currentSelection(): string | undefined {
    return this.selectedEntry;
  }

  /**
   *
   * @param entryName
   * @param forceUpdate if set to true, the entry will be reselected if it is already selected otherwise nothing will happen
   * @returns true if the entry was selected, false if the entry does not exist
   */
  public selectEntry(entryName: string | undefined, forceUpdate = false): boolean {
    if (!entryName || !this.cfgStateService.nameExists(entryName)) {
      this.onSelectionChange.next(undefined);
      this.selectedEntry = undefined;
      return false;
    }
    if (!forceUpdate && entryName === this.selectedEntry) {
      return true;
    }
    const entry = this.cfgStateService.getCfgUtil().getEntryByName<CmsGenericEntry>(entryName, true);
    this.selectedEntry = entryName;
    this.onSelectionChange.next(entry);
    return true;
  }

  /**
   * @param cls provide the class of the parent entry to show only allowed
   */
  public createNewElement(cls?: CLS) {
    this.onCreateNewElement.next(cls ? { cls } : {});
  }

  public removeNewElement() {
    this.onCreateNewElement.next(undefined);
  }

  public openCalculationManagerDialog(calculationName?: string): void {
    this.cmsDialogService
      .open(CalculationManagerComponent, {
        calculation: calculationName ? Create.calculationRef(calculationName) : undefined,
      })
      .onClose.subscribe(() => {
        this.cfgStateService.softRefresh();
      });
  }

  private updateCheckResults(): void {
    const cfgUtil = this.cfgStateService.getCfgUtil(true);
    if (cfgUtil) {
      const cfgChecker = new ConfigurationChecker(cfgUtil, this.cfgDataAndTemplateService);
      cfgChecker.check().then((checkResults) => {
        this.checkResults.next(checkResults);
      });
    } else {
      this.checkResults.next([]);
    }
  }
}
