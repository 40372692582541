import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Condition,
  CONDITIONAL_OPERATOR,
  Create,
  DATA_VALUE_TYPE,
  FieldRef,
  ICON,
  Is,
  ObjectUtil,
  VALUE_FIELD_TYPES,
} from '@kfd/core';
import { CONDITION_OPERATIONS, ConditionOperation, dataTypeIcon } from '../../../shared/global';
import { CfgDataAndTemplateService } from '../../cfg-data-and-template.service';

@Component({
  selector: 'kfd-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss'],
})
export class ConditionComponent {
  @Output()
  public conditionChange = new EventEmitter<Condition>();

  protected operationList: ConditionOperation[] = [];
  protected operation: ConditionOperation | undefined;
  protected dataValueType: DATA_VALUE_TYPE | undefined;
  protected dataTypeIcon: ICON | undefined;
  protected fieldTypes = VALUE_FIELD_TYPES;
  protected isValid = false;

  constructor(private cfgTemplateService: CfgDataAndTemplateService) {
    this.setEmptyCondition();
  }

  private _condition: Condition | undefined;

  get condition(): Condition | undefined {
    return this._condition;
  }

  @Input()
  set condition(value: Condition | undefined) {
    if (value === undefined) {
      this.setEmptyCondition();
      return;
    }
    if (JSON.stringify(value) === JSON.stringify(this._condition)) {
      return;
    }

    this._condition = ObjectUtil.clone(value);
    this.operation = CONDITION_OPERATIONS.find((cO) => cO.op === this._condition?.op);

    if (this._condition !== undefined) {
      if (Is.fieldRef(this._condition.value1)) {
        this.fieldRefChange(this._condition.value1);
      }
    }

    this.updateValidity();
  }

  public static isConditionValid(condition: Condition): boolean {
    if (!Is.fieldRef(condition.value1)) {
      return false;
    }
    if (!condition.value1.name) {
      return false;
    }
    const operation = CONDITION_OPERATIONS.find((value) => value.op === condition.op);
    if (!operation) {
      return false;
    }
    if (operation.value) {
      if (!Is.value(condition.value2)) {
        return false;
      }
      //todo check for valid input depending on type (text,number)
    }
    return true;
  }

  setEmptyCondition() {
    this._condition = Create.condition(
      Create.fieldRef(),
      CONDITIONAL_OPERATOR.EQUALS,
      Create.createDefaultInputValue(DATA_VALUE_TYPE.STRING),
    );
    this.updateValidity();
  }

  fieldRefChange(fieldRef: FieldRef) {
    if (!fieldRef.name) {
      this.operationList = [];
      return;
    }
    const dataValueType = this.cfgTemplateService.getFieldRefDataValueType(fieldRef);
    if (!dataValueType) {
      this.operationList = [];
      return;
    }
    this.dataValueType = dataValueType;
    this.dataTypeIcon = dataTypeIcon(this.dataValueType);

    this.operationList = CONDITION_OPERATIONS.filter(
      (conditionOperation) => conditionOperation.allowedTypes.indexOf(this.dataValueType) >= 0,
    );
    if (this.operationList.length > 0) {
      if (this.operation && !this.operationList.find((entry) => entry.op === this.operation?.op)) {
        this.operation = undefined;
        this.operationChange(this.operationList[0].op);
      }
    }
    this.updateValidity();
  }

  operationChange(op: CONDITIONAL_OPERATOR) {
    this.operation = this.operationList.find((value) => value.op === op);
    if (this.condition && this.operation) {
      this.condition.op = this.operation.op;
      if (this.operation.value === false) {
        this.condition.value2 = undefined;
      }
    }
    this.updateValidity();
  }

  onChange() {
    this.conditionChange.emit(this._condition);
  }

  updateValidity(): void {
    if (this.condition) {
      this.isValid = ConditionComponent.isConditionValid(this.condition);
    }
  }
}
