@if (calculation) {
  <ul
    class="param-list level-{{ level }} operation-{{ calculation.op }} text-sm"
    [ngClass]="{ 'sub-calc': level > 0, selected: allowEdit && editMode }"
  >
    @if (!calculation.valid) {
      <p-message
        severity="warn"
        text="Die Berechnung enthält fehlerhafte Werte"
        styleClass="small"
        class="mb-1"
      ></p-message>
    }
    @for (param of calculation.params; track param.id) {
      <li [attr.id]="param.id" #paramElement>
        @if (param.cls === CLS.CALCULATION && param?.excludeFromResult) {
          <div class="operation-icon pi {{ ICON.EXCLUDE }}" pTooltip="Wird nicht im Ergebnis berücksichtigt"></div>
        } @else {
          <div
            class="operation-icon pi {{ calcOperationIcons[calculation.op] }}"
            pTooltip="{{ calculationOperationLabels[calculation.op] }}"
          ></div>
        }
        @if (param.error) {
          <div class="invalid-icon color-error pi {{ ICON.WARN }}" [pTooltip]="param.error"></div>
        }
        @switch (param.cls) {
          @case (CLS.CALCULATION) {
            <ng-container
              [ngTemplateOutletContext]="{ param: param }"
              [ngTemplateOutlet]="calculationTpl"
            ></ng-container>
          }
          @case (CLS.INPUT_VALUE) {
            <ng-container
              [ngTemplateOutletContext]="{ param: param }"
              [ngTemplateOutlet]="inputValueTpl"
            ></ng-container>
          }
          <!--          @case (CLS.SELECTION_VALUE) {-->
            <!--            <ng-container-->
            <!--              [ngTemplateOutletContext]="{ param: param }"-->
            <!--              [ngTemplateOutlet]="selectionValueTpl"-->
            <!--            ></ng-container>-->
            <!--          }-->
          @case (CLS.ALIAS_VALUE) {
            <ng-container
              [ngTemplateOutletContext]="{ param: param }"
              [ngTemplateOutlet]="aliasValueTpl"
            ></ng-container>
          }
          @case (CLS.FIELD_REF) {
            <ng-container [ngTemplateOutletContext]="{ param: param }" [ngTemplateOutlet]="fieldRefTpl"></ng-container>
          }
          @case (CLS.BASE_DATA_REF) {
            <ng-container
              [ngTemplateOutletContext]="{ param: param }"
              [ngTemplateOutlet]="baseDataRefTpl"
            ></ng-container>
          }
          @case (CLS.CALCULATION_REF) {
            <ng-container
              [ngTemplateOutletContext]="{ param: param }"
              [ngTemplateOutlet]="calculationRefTpl"
            ></ng-container>
          }
          @default {
            <i>Option nicht verfügbar</i>
          }
        }
      </li>
    }

    @if (allowEdit && editMode) {
      <div class="flex justify-content-between">
        <p-button
          icon="pi {{ ICON.ADD }}"
          label="Neuer Wert"
          [text]="true"
          (click)="addParamOverlayPanel.toggle($event)"
        ></p-button>
        <p-button
          icon="pi {{ ICON.SETTINGS }}"
          label="Optionen"
          iconPos="right"
          [text]="true"
          (click)="editCalculationOverlay.toggle($event)"
        ></p-button>
      </div>
    }
    <div class="result">
      <div class="value" [pTooltip]="calculation.alias">
        =
        @if (calculation.label) {
          {{ calculation.label.text }}
        } @else {
          <i>{{ calculationOperationResults[calculation.op] }}</i>
        }
      </div>
      @if (getAliasForEdit()) {
        <div class="flex flex-column justify-content-center">
          <div class="tag alias-value" pTooltip="Referenz-Name für das Ergebnis dieser Berechnung">
            Alias: {{ getAliasForEdit() }}
          </div>
        </div>
      }
      @if (allowEdit && level > 0) {
        @if (editMode) {
          <p-button
            class="edit-calc-btn"
            icon="pi {{ ICON.DECLINE }}"
            pTooltip="Bearbeiten beenden"
            [rounded]="true"
            [text]="true"
            (click)="cancelEdit()"
          ></p-button>
        } @else {
          <p-button
            class="edit-calc-btn"
            icon="pi {{ ICON.EDIT }}"
            pTooltip="Bearbeiten"
            [rounded]="true"
            [text]="true"
            (click)="editMode = true"
          ></p-button>
        }
      }
    </div>
  </ul>
}

<p-overlayPanel #addParamOverlayPanel [dismissable]="true">
  <div class="p-inputgroup mobile-width">
    <p-dropdown #paramOption [options]="paramOptionList">
      <ng-template let-options pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div class="operation-icon pi {{ options.icon }}"></div>
          <div class="flex flex-column">
            <strong>{{ options.label }}</strong>
            <i>{{ options.description }}</i>
          </div>
        </div>
      </ng-template>
    </p-dropdown>
    <button (click)="addParam(paramOption.value)" icon="pi pi-plus" iconPos="left" pButton type="button"></button>
  </div>
</p-overlayPanel>

<p-overlayPanel #editCalculationOverlay [dismissable]="false" [showCloseIcon]="true">
  <div class="p-fluid mobile-width">
    <div class="p-field">
      <label>Ergebnis Label</label>
      <kfd-label #calculationLabel [label]="calculation.label"></kfd-label>
    </div>
    <div [ngClass]="{ hidden: level === 0 }" class="p-field">
      <label>Referenz</label>
      <kfd-toggle-input-field
        #calculationAlias
        [value]="getAliasForEdit()"
        hint="Das Ergebnis kann unter Verwendung des Alias referenziert werden"
      ></kfd-toggle-input-field>
    </div>
    <div [ngClass]="{ hidden: level === 0 }" class="p-field p-field-checkbox">
      <p-checkbox
        #calculationExcludeFromResult
        [disabled]="!calculation.alias"
        [ngModel]="calculation.excludeFromResult"
        binary="true"
        hint="Das Ergebnis wird nur unter eine Verwendung der Referenz berücksichtigt"
        label="Ergebnis aus Berechnung ausschließen"
      ></p-checkbox>
    </div>
    <div class="p-field">
      <label>Operation</label>
      <p-dropdown #calculationOperation [ngModel]="calculation.op" [options]="calcOperationList"></p-dropdown>
    </div>
    <kfd-toggle-setting
      [object]="calculation"
      [property]="'fn'"
      [trackObjectChanges]="false"
      class="p-field"
      label="Ergebnis anpassen"
    >
      <kfd-calculation-fn #calculationFn [fn]="calculation.fn"></kfd-calculation-fn>
    </kfd-toggle-setting>
    <p-button
      (click)="
        acceptCalculationSettings(
          $any({
            label: calculationLabel.label,
            alias: calculationAlias.value,
            excludeFromResult: calculationExcludeFromResult.checked,
            operation: calculationOperation.value,
            fn: calculationFn.fn
          })
        )
      "
      [outlined]="true"
      [rounded]="true"
      class="block mt-2"
      icon="pi {{ ICON.ACCEPT }}"
      label="Übernehmen"
    ></p-button>
  </div>
</p-overlayPanel>

<p-overlayPanel #editParamPanel [dismissable]="true" [showCloseIcon]="true">
  <div class="p-fluid mobile-width">
    @if (selectedParam) {
      @switch (selectedParam.cls) {
        @case (CLS.INPUT_VALUE) {
          <kfd-data-value-select
            [value]="selectedParam"
            [allowedTypes]="allowedDataValueTypes"
            [showAcceptBtn]="true"
            (valueChange)="acceptParam(selectedParam.id, $event)"
          ></kfd-data-value-select>
        }
        @case (CLS.ALIAS_VALUE) {
          @if (calculationHandler) {
            <kfd-alias-ref
              [aliasValue]="selectedParam"
              [sourceAlias]="calculation.alias"
              [showAcceptBtn]="true"
              [calculationHandler]="rootCalculationHandler"
              (aliasValueChange)="acceptParam(selectedParam.id, $event)"
            ></kfd-alias-ref>
          }
        }
        @case (CLS.BASE_DATA_REF) {
          <kfd-base-data-value-ref
            [baseDataRef]="selectedParam"
            [dataTypes]="[DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE]"
            (baseDataRefChange)="acceptParam(selectedParam.id, $event)"
          ></kfd-base-data-value-ref>
        }
        @case (CLS.FIELD_REF) {
          <kfd-field-ref
            [fieldRef]="selectedParam"
            (fieldRefChange)="acceptParam(selectedParam.id, $event)"
            [fieldTypes]="[FIELD_TYPES.NUMBER, FIELD_TYPES.DATE]"
            [inline]="true"
            [showAcceptBtn]="true"
          ></kfd-field-ref>
        }
        @case (CLS.CALCULATION_REF) {
          <kfd-calculation-ref
            [calculationRef]="selectedParam"
            (calculationRefChange)="acceptParam(selectedParam.id, $event)"
          ></kfd-calculation-ref>
        }
        @default {
          <i>Keine Einstellungen verfügbar</i>
        }
      }
    } @else {
      <i>Kein Parameter ausgewählt</i>
    }
  </div>
</p-overlayPanel>

<ng-template #calculationTpl let-param="param">
  <div class="value">
    <kfd-calculation-editor
      (calculationChange)="acceptParam(param.id, $event)"
      [allowEdit]="allowEdit"
      [calculation]="param"
      [level]="level + 1"
      [rootCalculationHandler]="rootCalculationHandler"
      class="sub-calculation"
    ></kfd-calculation-editor>
    <!--      [editMode]="param.id === selectedParam?.id"-->
    <!--    ></kfd-calculation-editor>-->
    @if (allowEdit && editMode) {
      <div class="actions">
        <kfd-delete-confirm-btn tooltip="Entfernen" (confirmed)="deleteParam(param)"></kfd-delete-confirm-btn>
      </div>
    }
  </div>
</ng-template>

<ng-template #inputValueTpl let-param="param">
  <div class="value">
    <div class="tag input-value" pTooltip="Eingetragener Wert">Wert: {{ param.value }}</div>
    @if (allowEdit && editMode) {
      <div class="actions">
        <p-button
          icon="pi {{ ICON.EDIT }}"
          pTooltip="Bearbeiten"
          [rounded]="true"
          [text]="true"
          (click)="selectParam($event, param)"
        ></p-button>
        <kfd-delete-confirm-btn tooltip="Entfernen" (confirmed)="deleteParam(param)"></kfd-delete-confirm-btn>
      </div>
    }
  </div>
</ng-template>

<!--<ng-template #selectionValueTpl let-param="param">-->
<!--  <div class="value">-->
<!--    <i>not yet supported</i>-->
<!--  </div>-->
<!--</ng-template>-->

<ng-template #aliasValueTpl let-param="param">
  <div class="value">
    <div class="tag alias-value" pTooltip="Ergebnis einer anderen Kalkulation">Alias: {{ param.name }}</div>
    @if (allowEdit && editMode) {
      <div class="actions">
        <p-button
          icon="pi {{ ICON.EDIT }}"
          pTooltip="Bearbeiten"
          [rounded]="true"
          [text]="true"
          (click)="selectParam($event, param)"
        ></p-button>
        <kfd-delete-confirm-btn tooltip="Entfernen" (confirmed)="deleteParam(param)"></kfd-delete-confirm-btn>
      </div>
    }
  </div>
</ng-template>

<ng-template #fieldRefTpl let-param="param">
  <div class="value">
    <div class="tag field-ref" pTooltip="Wert des referenzierten Feldes">Feld: {{ param.name | entrylabel }}</div>
    @if (allowEdit && editMode) {
      <div class="actions">
        <p-button
          icon="pi {{ ICON.EDIT }}"
          pTooltip="Bearbeiten"
          [rounded]="true"
          [text]="true"
          (click)="selectParam($event, param)"
        ></p-button>
        <kfd-delete-confirm-btn tooltip="Entfernen" (confirmed)="deleteParam(param)"></kfd-delete-confirm-btn>
      </div>
    }
  </div>
</ng-template>

<ng-template #baseDataRefTpl let-param="param">
  <div class="value">
    <div class="tag base-data-ref" pTooltip="Wert des referenzierten Datensatzes">Datensatz: {{ param.name }}</div>
    @if (allowEdit && editMode) {
      <div class="actions">
        <p-button
          icon="pi {{ ICON.EDIT }}"
          pTooltip="Bearbeiten"
          [rounded]="true"
          [text]="true"
          (click)="selectParam($event, param)"
        ></p-button>
        <kfd-delete-confirm-btn tooltip="Entfernen" (confirmed)="deleteParam(param)"></kfd-delete-confirm-btn>
      </div>
    }
  </div>
</ng-template>

<ng-template #calculationRefTpl let-param="param">
  <div class="value">
    <div class="tag calculation-ref" pTooltip="Wert der referenzierten Berechnung">Berechnung: {{ param.name }}</div>
    @if (allowEdit && editMode) {
      <div class="actions">
        <p-button
          icon="pi {{ ICON.EDIT }}"
          pTooltip="Bearbeiten"
          [rounded]="true"
          [text]="true"
          (click)="selectParam($event, param)"
        ></p-button>
        <kfd-delete-confirm-btn tooltip="Entfernen" (confirmed)="deleteParam(param)"></kfd-delete-confirm-btn>
      </div>
    }
  </div>
</ng-template>
