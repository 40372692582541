import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AliasValue, CalculationHandler, Create, ICON, Is } from '@kfd/core';

@Component({
  selector: 'kfd-alias-ref',
  templateUrl: './alias-ref.component.html',
  styleUrls: ['./alias-ref.component.scss'],
})
export class AliasRefComponent {
  @Output()
  public aliasValueChange = new EventEmitter<AliasValue>();

  public aliase: string[] = [];

  // shows accept button and disable automatic updates
  @Input()
  public showAcceptBtn = false;

  protected readonly ICON = ICON;

  private _sourceAlias: string | undefined;
  private _aliasValue: AliasValue = Create.aliasValue();
  private _calculationHandler: CalculationHandler | undefined;

  get aliasValue(): AliasValue {
    return this._aliasValue;
  }

  @Input()
  set aliasValue(value: AliasValue) {
    this._aliasValue = value;
  }

  @Input()
  set sourceAlias(value: string) {
    this._sourceAlias = value;
    this.updateAliase();
  }

  get calculationHandler(): CalculationHandler | undefined {
    return this._calculationHandler;
  }

  @Input()
  set calculationHandler(value: CalculationHandler | undefined) {
    if (value === undefined) {
      return;
    }
    this._calculationHandler = value;
    this.updateAliase();
  }

  private updateAliase(): void {
    if (this._calculationHandler === undefined || this._sourceAlias === undefined) {
      return;
    }
    this.aliase = this._calculationHandler.getAllowedAliases(this._sourceAlias);
    // console.log(this._calculationHandler.getCalculation().alias, this._sourceAlias, this.aliase);
  }

  protected nameChange(name: string): void {
    this._aliasValue.name = name;
    if (!this.showAcceptBtn) {
      this.save();
    }
  }

  protected save() {
    if (!Is.aliasValue(this.aliasValue)) {
      return;
    }
    this.aliasValueChange.emit(this.aliasValue);
  }
}
