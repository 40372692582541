import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  BaseDataTemplateValue,
  Create,
  DATA_VALUE_TYPE,
  DATAHANDLER_TYPES,
  FIELD_TYPES,
  Is,
  MultiSelectFieldConfig,
  SELECT_FIELD_LAYOUTS,
  SelectOption,
} from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';
import { CmsContextService } from '../../../../services/cms-context.service';
import { CmsDialogService } from '../../../../services/cms-dialog.service';
import { BasedataHandlerDialogComponent } from '../../common/basedata-handler-dialog/basedata-handler-dialog.component';
import { ApiBaseDataTemplateService } from '../../../../services/api/api-base-data-template.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'kfd-field-settings-details-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectEntryFieldSettingsComponent extends BaseEntryFieldSettingsComponent<MultiSelectFieldConfig> {
  protected layoutOptions: SelectOption[] = [
    {
      label: '---',
      value: '',
    },
    {
      label: 'Liste',
      value: SELECT_FIELD_LAYOUTS.BUTTON,
    },
    {
      label: 'Dropdown',
      value: SELECT_FIELD_LAYOUTS.DROPDOWN,
    },
  ];
  protected dataValueType = DATA_VALUE_TYPE;
  protected defaultMinValue = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 0);
  protected defaultMaxValue = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 1);
  protected additionalFieldOptions$: Observable<SelectOption<BaseDataTemplateValue>[]>;
  protected readonly dataHandlerTypes = DATAHANDLER_TYPES;
  protected readonly SELECT_FIELD_LAYOUTS = SELECT_FIELD_LAYOUTS;

  public constructor(
    protected cd: ChangeDetectorRef,
    protected cmsDialogService: CmsDialogService,
    public ctx: CmsContextService,
    private apiBaseDataTemplateService: ApiBaseDataTemplateService,
  ) {
    super(cd);
  }

  public get config(): MultiSelectFieldConfig | undefined {
    return this._config;
  }

  @Input()
  public set config(value: MultiSelectFieldConfig | undefined) {
    super.config = value;
    this.loadAdditionalFields();
  }

  public showDataValuePanel() {
    this.cmsDialogService
      .open(BasedataHandlerDialogComponent, {
        dataHandler: this._config?.dataHandler,
      })
      .onClose.subscribe((dataHandler) => {
        if (dataHandler) {
          this.change('dataHandler', dataHandler);
        }
      });
  }

  protected getType(): FIELD_TYPES {
    return FIELD_TYPES.SELECT;
  }

  protected enableMultiSelect(): void {
    // change config silently to avoid emitting default values
    this.config.min = this.defaultMinValue;
    this.config.max = this.defaultMaxValue;
  }

  private loadAdditionalFields(): void {
    if (!Is.dynamicDataHandler(this.config.dataHandler) || !this.config.dataHandler.templateName) {
      this.additionalFieldOptions$ = undefined;
      return;
    }

    this.additionalFieldOptions$ = this.apiBaseDataTemplateService.getTemplateValues(
      this.ctx.projectId,
      this.config.dataHandler.templateName,
      true,
    );
  }
}
