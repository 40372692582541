<div [kfdBlock]="loading" class="flex-1 flex" data-automationId="preview-dialog">
  <p-button
    (click)="close()"
    [rounded]="true"
    class="close-btn"
    data-automationId="dialog-accept-btn"
    icon="pi {{ ICON.DECLINE }}"
  ></p-button>

  @if (cmsConfigurator) {
    <div [attr.data-automationId]="'preview-dialog-for-' + cmsConfigurator.code" class="flex-1 kfd-container flex">
      <kfd-cfg-layout-page></kfd-cfg-layout-page>
    </div>
  }
</div>
