<div class="p-inputgroup">
  <p-dropdown
    [options]="cfgStateService.getCfgUtil().getCalculations()"
    [(ngModel)]="selection"
    optionLabel="label"
    optionValue="name"
    placeholder="Berechnung auswählen"
  >
  </p-dropdown>
  <p-button (onClick)="select()" icon="pi {{ ICON.ACCEPT }}" pTooltip="Übernehmen"></p-button>
</div>
