@if (form) {
  <ng-container [formGroup]="form">
    <div
      class="p-field"
      data-automationId="field-label"
      [ngClass]="{ invalid: form.get('label')?.touched && form.get('label')?.invalid }"
    >
      <label>Label*</label>
      <input data-automationId="field-label-input" formControlName="label" pInputText required type="text" />
      @if (form.get('label')?.getError('required')) {
        <small class="p-error">Diese Feld ist ein Pflichtfeld</small>
      } @else if (form.get('label')?.getError('minlength')) {
        <small class="p-error">Das Label muss mindestens 4 Zeichen lang sein</small>
      } @else if (form.get('label')?.getError('maxlength')) {
        <small class="p-error">Das Label darf maximal 50 Zeichen lang sein</small>
      }
    </div>
    <div
      class="p-field"
      data-automationId="field-name"
      [ngClass]="{ invalid: form.get('name')?.touched && form.get('name')?.invalid }"
    >
      <label>Name*</label>
      <kfd-toggle-input-field #nameInputField formControlName="name"
                              (editModeChange)="manualNameChange = true"></kfd-toggle-input-field>
      @if (form.get('name')?.getError('required')) {
        <small class="p-error">Diese Feld ist ein Pflichtfeld</small>
      } @else if (form.get('name')?.getError('minlength')) {
        <small class="p-error">Der Name muss mindestens 4 Zeichen lang sein</small>
      } @else if (form.get('name')?.getError('maxlength')) {
        <small class="p-error">Der Name darf maximal 30 Zeichen lang sein</small>
      } @else {
        @if (form.get('name')?.errors?.message) {
          <small class="p-error">{{ form.get('name')?.errors.message }}</small>
        }
      }
      <small>Der Name ist öffentlich sichtbar und sollte nicht nach dem Veröffentlichen verändert werden.</small>
    </div>
    @if (form.get('code')) {
      <div
        class="p-field"
        data-automationId="field-code"
        [ngClass]="{ invalid: form.get('code')?.touched && form.get('code')?.invalid }"
      >
        <label>Code*</label>
        <kfd-toggle-input-field #codeInputField formControlName="code"
                                (editModeChange)="manualCodeChange = true"></kfd-toggle-input-field>
        @if (form.get('code')?.getError('required')) {
          <small class="p-error">Diese Feld ist ein Pflichtfeld</small>
        } @else if (form.get('code')?.getError('minlength')) {
          <small class="p-error">Der Code muss mindestens 3 Zeichen lang sein</small>
        } @else if (form.get('code')?.getError('maxlength')) {
          <small class="p-error">Der Code muss mindestens 5 Zeichen lang sein</small>
        } @else {
          @if (form.get('code')?.errors?.message) {
            <small class="p-error">{{ form.get('code')?.errors.message }}</small>
          }
        }
        <small>Der Code ist für die interne Verwendung und sollte nicht nach dem Veröffentlichen verändert
          werden.</small>
      </div>
    }
  </ng-container>
}
