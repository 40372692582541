import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseDataValueRef, CmsSelectionData, Create, DATA_VALUE_TYPE, ICON } from '@kfd/core';
import { CfgDataAndTemplateService } from '../../cfg-data-and-template.service';

@Component({
  selector: 'kfd-base-data-value-ref',
  templateUrl: './base-data-value-ref.component.html',
  styleUrl: './base-data-value-ref.component.scss',
})
export class BaseDataValueRefComponent implements OnInit {
  /**
   * Data types (of values) that are allowed to be selected
   * if undefined all data types are allowed and entries without values
   */
  private _dataTypes: DATA_VALUE_TYPE[] | undefined;

  protected _baseDataRef: BaseDataValueRef | undefined;
  protected readonly ICON = ICON;
  protected optionList: CmsSelectionData[] = [];
  protected selection: BaseDataValueRef | undefined;

  @Output()
  public baseDataRefChange = new EventEmitter<BaseDataValueRef>();

  constructor(private readonly cfgDataAndTplService: CfgDataAndTemplateService) {}

  public ngOnInit(): void {
    this.loadBaseDataEntries();
  }

  public get baseDataRef(): BaseDataValueRef | undefined {
    return this._baseDataRef;
  }

  @Input()
  public set baseDataRef(value: BaseDataValueRef | undefined) {
    this._baseDataRef = value;
    const baseDateEntry = this.cfgDataAndTplService.getBaseDataEntries().find((entry) => entry.name === value?.name);
    if (baseDateEntry && baseDateEntry.values.find((v) => v.identifier === value?.property)) {
      this.selection = value;
    }
  }

  public get dataTypes(): DATA_VALUE_TYPE[] | undefined {
    return this._dataTypes;
  }

  @Input()
  public set dataTypes(value: DATA_VALUE_TYPE[] | undefined) {
    this._dataTypes = value;
    this.loadBaseDataEntries();
  }

  protected loadBaseDataEntries(): void {
    this.optionList = this.cfgDataAndTplService
      .getBaseDataEntries()
      .filter((entry) => {
        if (!this.dataTypes) {
          return true;
        }
        if (!entry.values) {
          return false;
        }
        return entry.values.some((value) => this.dataTypes.includes(value.type));
      })
      .map((option) => {
        return {
          ...option,
          items: option.values.map((value) => ({
            label: `${value.label.text.toString()}: ${value.value.toString()}`,
            value: Create.baseDataValueRef(option.name, value.identifier),
          })),
        };
      });
  }

  protected select() {
    if (!this.selection) {
      return;
    }
    this.baseDataRefChange.emit(this.selection);
  }
}
