import { Component, Input } from '@angular/core';
import { CfgUtil, CheckResult, CheckResultSeverity, CmsConfigurator, ConfigurationChecker, ICON } from '@kfd/core';
import { CmsContextService } from '../../../services/cms-context.service';
import { environment } from '../../../../environments/environment';
import { CmsDialogService } from '../../../services/cms-dialog.service';
import { ShowConfigurationDialogComponent } from '../../../shared/components/show-configuration-dialog/show-configuration-dialog.component';
import { ApiConfigurationService } from '../../../services/api/api-configuration.service';
import { CfgStateService } from '../../cfg-state.service';
import { mergeMap } from 'rxjs';
import { CfgDataAndTemplateService } from '../../cfg-data-and-template.service';

// import { DataUsageInfoDialogComponent } from '../../configurator/data-usage-info-dialog/data-usage-info-dialog.component';

@Component({
  selector: 'kfd-configuration-publishing',
  templateUrl: './publishing.component.html',
  styleUrls: ['./publishing.component.scss'],
})
export class PublishingComponent {
  protected readonly environment = environment;
  protected readonly ICON = ICON;
  protected loading = false;
  protected cfgCheckResults: CheckResult[] | undefined;
  protected hasErrors: boolean | undefined;

  constructor(
    private readonly apiConfigurationService: ApiConfigurationService,
    private readonly cfgStateService: CfgStateService,
    private readonly cmsDialogService: CmsDialogService,
    private readonly cfgDataAndTemplateService: CfgDataAndTemplateService,
    protected ctx: CmsContextService,
  ) {}

  private _configuration: CmsConfigurator | undefined;

  public get configuration(): CmsConfigurator | undefined {
    return this._configuration;
  }

  @Input()
  public set configuration(value: CmsConfigurator | undefined) {
    this._configuration = value;
    const cfgChecker = new ConfigurationChecker(CfgUtil.create(this.configuration), this.cfgDataAndTemplateService);
    cfgChecker.check().then((results) => {
      this.cfgCheckResults = results;
      this.hasErrors = results.some((result) => result.severity === CheckResultSeverity.ERROR);
    });
  }

  openPublished() {
    if (this._configuration === undefined) {
      return;
    }
    this.cmsDialogService.open(
      ShowConfigurationDialogComponent,
      {
        projectId: this.ctx.projectId,
        configurationId: this.ctx.configuratorId,
        preview: false,
        versions: this._configuration.versions,
      },
      {
        dismissibleMask: false,
        fullHeight: true,
      },
    );
  }

  draft() {
    this.loading = true;
    this.apiConfigurationService
      .draft(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(mergeMap(() => this.cfgStateService.refresh()))
      .subscribe({
        next: () => {
          this.loading = false;
          // if (this.configuration) {
          //   // this.configurationService.resetEntry(this.configuration._id);
          // }
          this._configuration = this.cfgStateService.getCfgUtil().getCfg();
        },
        error: (e) => {
          this.loading = false;
          throw e;
        },
      });
  }

  publish() {
    this.loading = true;
    this.apiConfigurationService
      .publish(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(mergeMap(() => this.cfgStateService.refresh()))
      .subscribe({
        next: () => {
          this.loading = false;
          this._configuration = this.cfgStateService.getCfgUtil().getCfg();
        },
        error: (e) => {
          this.loading = false;
          throw e;
        },
      });
  }

  removeDraft() {
    this.loading = true;
    this.apiConfigurationService
      .removeDraft(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(mergeMap(() => this.cfgStateService.refresh()))
      .subscribe({
        next: () => {
          this.loading = false;
          this._configuration = this.cfgStateService.getCfgUtil().getCfg();
        },
        error: (e) => {
          this.loading = false;
          throw e;
        },
      });
  }

  removePublished() {
    this.loading = true;
    this.apiConfigurationService
      .removePublished(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(mergeMap(() => this.cfgStateService.refresh()))
      .subscribe({
        next: () => {
          this.loading = false;
          this._configuration = this.cfgStateService.getCfgUtil().getCfg();
        },
        error: (e) => {
          this.loading = false;
          throw e;
        },
      });
  }

  protected openDraft() {
    if (this._configuration === undefined) {
      return;
    }
    this.cmsDialogService.open(
      ShowConfigurationDialogComponent,
      {
        projectId: this.ctx.projectId,
        configurationId: this.ctx.configuratorId,
        preview: true,
        versions: this._configuration.versions,
      },
      {
        dismissibleMask: false,
        fullHeight: true,
      },
    );
  }
}
