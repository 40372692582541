import { Routes } from '@angular/router';
import { CMS_APP_ROUTE_PARAMS } from '@kfd/core';
import { InternalComponent } from './internal.component';

const internalPath = `:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}/internal`;

export const internalRoutes: Routes = [
  {
    path: `${internalPath}/calculation`,
    component: InternalComponent,
  },
];
